<template>
	<AppLayout>
		<AboutUs :current-section="getSectionById('about-us')" :sections="sections" data-progress-step />
		<OurClients :current-section="getSectionById('our-clients')" :sections="sections" data-progress-step />
		<ServiceSection data-progress-step />
		<ContentSection :current-section="getSectionById('content-section')" :sections="sections" data-progress-step />
	</AppLayout>
</template>

<script>
import AppLayout from '@/components/AppLayout.vue';
import AboutUs from '@/components/AboutUsView/AboutUsModified.vue';
import OurClients from '@/components/OurClients.vue';
import ServiceSection from '@/components/AboutUsView/ServiceSection.vue';
import ContentSection from '@/components/AboutUsView/ContentSection.vue';

export default {
	name: 'PrivacyPolicyView',
	components: { AppLayout, ServiceSection, AboutUs, OurClients, ContentSection },
	data: () => ({
		sections: [
			{
				id: 'about-us',
				title: 'Your WEB3 Partner',
			},
			{
				id: 'our-clients',
				title: 'Trusted By',
			},
			{
				id: 'content-section',
				title: 'WE ARE EXPERT',
			},
		],
	}),
	methods: {
		getSectionById(sectionId) {
			return this.sections.find(({ id }) => id === sectionId);
		},
	},
};
</script>
