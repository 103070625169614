<template>
	<RouterLink v-if="buttonType === 'link' && to" :to="to" class="base-circle-button">
		<span class="base-circle-button__label">
			<slot></slot>
		</span>

		<span class="base-circle-button__icon">
			<IconArrow class="icon" />
		</span>
	</RouterLink>

	<a v-else-if="buttonType === 'link' && url" :href="url" class="base-circle-button">
		<span class="base-circle-button__label">
			<slot></slot>
		</span>

		<span class="base-circle-button__icon">
			<IconArrow class="icon" />
		</span>
	</a>

	<button v-else-if="buttonType === 'button'" class="base-circle-button">
		<span class="base-circle-button__label">
			<slot></slot>
		</span>

		<span class="base-circle-button__icon">
			<IconArrow class="icon" />
		</span>
	</button>
</template>

<script>
import IconArrow from '@/components/icons/IconArrow.vue';

export default {
	name: 'BaseCircleButton',
	components: { IconArrow },
	props: {
		buttonType: {
			type: String,
			required: false,
			default: 'button',
			validator(value) {
				return ['button', 'link'].includes(value);
			},
		},
		to: {
			type: Object,
			required: false,
		},
		url: {
			type: String,
			required: false,
		},
	},
};
</script>
