<template>
	<VeeForm class="form" :class="[formClass, {'message-visible': messageVisibility}]" @submit="onSubmit" ref="form">
		<div class="form__row">
			<div class="form__field">
				<label :for="`${name}-${$.uid}[name]`" class="form__label visually-hidden">Name</label>
				<VeeField
					:id="`${name}-${$.uid}[name]`"
					name="name"
					placeholder="NAME"
					rules="required"
					label="NAME"
					class="form__input"
				/>
				<VeeErrorMessage name="name" class="form__error" />
			</div>

			<div class="form__field">
				<label :for="`${name}-${$.uid}[email]`" class="form__label visually-hidden">Email</label>
				<VeeField
					:id="`${name}-${$.uid}[email]`"
					name="email"
					placeholder="EMAIL"
					rules="required|email"
					label="EMAIL"
					class="form__input"
				/>
				<VeeErrorMessage name="email" class="form__error" />
			</div>
		</div>

		<div class="form__row">
			<div class="form__field">
				<label :for="`${name}-${$.uid}[questions]`" class="form__label visually-hidden">Your Questions</label>
				<VeeField
					:id="`${name}-${$.uid}[questions]`"
					name="questions"
					placeholder="YOUR QUESTIONS"
					rules="required"
					label="YOUR QUESTIONS"
					class="form__input"
				/>
				<VeeErrorMessage name="questions" class="form__error" />
			</div>
		</div>

		<BaseCircleButton class="form__submit" type="submit">Submit</BaseCircleButton>
	</VeeForm>

	<div v-if="messageVisibility" class="form-message" :class="isErrorMessage ? 'error' : ''">
		{{ userMessage }}
	</div>
</template>

<script>
import { Form as VeeForm, Field as VeeField, ErrorMessage as VeeErrorMessage } from 'vee-validate';
import BaseCircleButton from '@/components/BaseCircleButton.vue';

export default {
	name: 'QuestionForm',
	components: { BaseCircleButton, VeeForm, VeeField, VeeErrorMessage },
	data: () => ({
		name: 'question-form',
		userMessage: '',
		messageVisibility: false,
		isErrorMessage: false,
	}),
	props: {
		formClass: {
			type: String,
			default: '',
		},
	},
	methods: {
		async onSubmit(values) {
			let data = {
				subject: 1,
				...values
			}
			try {
				const response = await fetch('/mail.php', {
					method: "POST",
					body: JSON.stringify(data),
					headers: {
						"Content-Type": "application/json",
					},
				});
				const json = await response.json();
				if (json.status === 'error') {
					let errorMessage;
					if (Array.isArray(json.errors)) {
						errorMessage = json.errors.join(', ');
					} else {
						errorMessage = json.errors;
					}
					this.onError()
					throw new Error(`Something went wrong: ${errorMessage}`);
				} else {
					this.onSuccess()
				}
			} catch (error) {
				this.onError()
				console.error("Ошибка:", error);
			}
		},
		onError() {
			this.userMessage = 'Something went wrong. Try again later';
			this.isErrorMessage = true
			this.setMessageVisibilty(true)
		},
		onSuccess() {
			this.userMessage = 'Thank you for contacting us!'
			this.isErrorMessage = false
			this.setMessageVisibilty(true)
			this.$refs.form.resetForm()
		},
		setMessageVisibilty(value) {
			this.messageVisibility = value
		}
	},
};
</script>
