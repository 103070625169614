<template>
	<canvas id="grid-canvas" :width="documentElementClientWidth" :height="documentElementClientHeight"></canvas>
</template>

<script>
import { mapState } from 'pinia';
import { useAppStore } from '@/stores/app.js';
import Experience from '@experience/Experience.js';
import { isMobile } from '@/helpers/isMobile';

export default {
	name: 'GridCanvas',
	data: () => ({
		documentElementClientWidth: 0,
		documentElementClientHeight: 0,
		mouseClientX: 0,
		mouseClientY: 0,
		cellWidth: 10,
		cellHeight: 10,
		strokeWidth: 1,
		radius: 30,
		ctx: null,
		animationFrameId: null,
		scaleRatio: 4,
	}),
	computed: {
		...mapState(useAppStore, ['customScrollTranslateX', 'customScrollTranslateY', 'gridScrollIsEnabled']),
		coordX() {
			return -this.customScrollTranslateX % this.cellWidth;
		},
		coordY() {
			if (this.gridScrollIsEnabled) {
				return -this.customScrollTranslateY % this.cellHeight;
			}
			return 0;
		},
	},
	mounted() {
		this.ctx = this.$el.getContext('2d', { willReadFrequently: true });
		this.bufferCanvas = document.createElement('canvas');
		this.bufferCtx = this.bufferCanvas.getContext('2d');
		this.current = Date.now();
		this.nextTime = 0;
		this.delay = 10; // m seconds
		this.deltaTime = 0.016666666666666668;
		this.start = this.current;
		this.elapsed = 0;
		this.isMobile = isMobile.any();

		window.addEventListener('resize', this.onResize);
		window.addEventListener('mousemove', this.onMouseMove);

		this.onResize();

		this.animationFrameId = window.requestAnimationFrame(this.draw);

		window.addEventListener('3d-app:ready', () => {
			this.experience = Experience.getInstance();

			window.dispatchEvent(
				new CustomEvent('3d-app:canvas-grid', {
					detail: {
						canvas: this.bufferCanvas,
					},
				}),
			);
		});
	},
	beforeUnmount() {
		window.removeEventListener('resize', this.onResize);
		window.removeEventListener('mousemove', this.onMouseMove);
		window.cancelAnimationFrame(this.animationFrameId);
	},
	methods: {
		onMouseMove(event) {
			this.mouseClientX = event.clientX / this.scaleRatio;
			this.mouseClientY = event.clientY / this.scaleRatio;
		},
		onResize() {
			this.documentElementClientWidth = document.documentElement.clientWidth / this.scaleRatio;
			this.documentElementClientHeight = document.documentElement.clientHeight / this.scaleRatio;

			this.bufferCanvas = document.createElement('canvas');
			this.bufferCanvas.width = this.documentElementClientWidth;
			this.bufferCanvas.height = this.documentElementClientHeight;

			this.bufferCtx = this.bufferCanvas.getContext('2d', { willReadFrequently: true });

			const imgData = this.ctx.getImageData(0, 0, this.documentElementClientWidth, this.documentElementClientHeight);
			this.bufferCtx.putImageData(imgData, 0, 0);

			window.dispatchEvent(
				new CustomEvent('3d-app:canvas-grid', {
					detail: {
						canvas: this.bufferCanvas,
					},
				}),
			);
		},
		draw(time) {
			this.current = Date.now();
			this.elapsed = (this.current - this.start) * 0.001;

			if (time < this.nextTime) {
				this.animationFrameId = window.requestAnimationFrame(this.draw);
				return;
			}

			this.nextTime = time + this.delay;

			this.ctx.clearRect(0, 0, this.documentElementClientWidth, this.documentElementClientHeight);

			const gradient = this.ctx.createRadialGradient(
				this.mouseClientX,
				this.mouseClientY,
				0,
				this.mouseClientX,
				this.mouseClientY,
				this.radius,
			);
			gradient.addColorStop(0, '#01D28E');
			gradient.addColorStop(1, 'transparent');

			if ( !this.isMobile ) {
				this.ctx.beginPath();
				this.ctx.arc(this.mouseClientX, this.mouseClientY, this.radius, 0, 2 * Math.PI, false);

				this.ctx.fillStyle = gradient;
				this.ctx.fill();
				this.ctx.save();
			}


			for (let x = this.coordX; x < this.documentElementClientWidth + this.cellWidth; x += this.cellWidth) {
				for (let y = this.coordY; y < this.documentElementClientHeight + this.cellHeight; y += this.cellHeight) {
					this.ctx.fillStyle = '#000000';
					this.ctx.fillRect(x, y, this.cellWidth - this.strokeWidth, this.cellHeight - this.strokeWidth);
				}
			}

			this.ctx.restore();

			this.bufferCtx.clearRect(0, 0, this.documentElementClientWidth, this.documentElementClientHeight);
			const imgData = this.ctx.getImageData(0, 0, this.documentElementClientWidth, this.documentElementClientHeight);
			this.bufferCtx.putImageData(imgData, 0, 0);

			if (this.experience) {
				this.experience.world.cube.gridTexture.needsUpdate = true;
			}

			if ( !this.isMobile ) {


				this.animationFrameId = window.requestAnimationFrame(this.draw);
			}
		},
	},
};
</script>
