<template>
	<section :id="currentSection.id" class="about-us about-us-modified">
		<div class="about-us__container container">
			<div class="about-us__top">
				<AppPagination :current-section="currentSection" :sections="sections" />

				<span class="about-us__subtitle font-heading">
					Crafting Digital Marvels
				</span>

				<div class="about-us__location">
					<div class="about-us__location-circles">
						<span class="about-us__location-circle"></span>
						<span class="about-us__location-circle"></span>
					</div>

					<p class="about-us__location-title m-0">
						<small>Location:</small>
						EMEA and MENA
					</p>
				</div>
			</div>

			<h1 class="about-us__title c-primary font-heading-secondary uppercase m-0">
				Saguni Studios
			</h1>

			<div class="about-us__main">
				<p class="about-us__desc m-0 font-heading">
					Experience the full entertainment package at Saguni Studios! From games to digital worlds and stories, our
					passionate team of industry experts is here to redefine your digital experience. Get ready for creativity,
					excellence, and a whole new level of entertainment!
				</p>
			</div>

			<div class="about-us__bottom">
				<div class="about-us__image">
					<img
						src="@/assets/images/about-us-image.jpg"
						alt="side view young man talking with multiple players using headphone while playing shooter games computer with green screen neon light"
						class="cover-image"
					/>
				</div>

				<blockquote class="about-us__quote m-0">
					Our team of experts brings a wealth of knowledge and experience to every project, ensuring that we deliver
					high-quality solutions that meet the needs of our clients.
				</blockquote>
			</div>
		</div>
	</section>
</template>

<script>
import AppPagination from '@/components/AppPagination.vue';

export default {
	name: 'AboutUsModified',
	components: { AppPagination },
	props: {
		currentSection: {
			type: Object,
			required: true,
		},
		sections: {
			type: Array,
			required: true,
		},
	},
};
</script>
