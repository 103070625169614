<template>
	<ScreenshotCanvas v-if="is3dRequired" />
	<GridCanvas />
	<ThreeCanvas v-if="viteDevWithCanvas && is3dRequired" />
	<RouterView />
	<AppHeader />
</template>

<script>
import ThreeCanvas from '@/components/ThreeCanvas.vue';
import { RouterView } from 'vue-router';
import { mapState, mapActions } from 'pinia';
import { useAppStore } from '@/stores/app.js';
import getScrollbarWidth from '@/helpers/get-scrollbar-width.js';
import AppHeader from '@/components/AppHeader.vue';
import { disableScroll } from '@/helpers/page-scroll.js';

// gsap
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import GridCanvas from '@/components/GridCanvas.vue';
import ScreenshotCanvas from '@/components/ScreenshotCanvas.vue';
gsap.registerPlugin(ScrollTrigger);

export default {
	name: 'App',
	components: { ScreenshotCanvas, GridCanvas, ThreeCanvas, RouterView, AppHeader },
	provide() {
		return {
			showPreloader: this.showPreloader,
			hidePreloader: this.hidePreloader,
		};
	},
	data: () => ({
		preloader: null,
		is3dRequired: false,
	}),
	computed: {
		...mapState(useAppStore, ['scrollbarWidth']),
		viteDevWithCanvas() {
			return import.meta.env.VITE_DEV_WITH_CANVAS === 'true';
		},
	},
	watch: {
		scrollbarWidth(value) {
			const result = value > 0 ? `${value}px` : value;
			document.documentElement.style.setProperty('--scrollbar-width', result);
		},
	},
	created() {
		const searchParams = new URLSearchParams(window.location.search);
		this.setDebug(searchParams.has('debug'));

		if (window.location.pathname === '/') {
			this.is3dRequired = true;
		}
	},
	mounted() {
		this.preloader = document.querySelector('#preloader');
		disableScroll();
		this.setScrollbarWidth(getScrollbarWidth());

		window.addEventListener('load', this.hidePreloader);
	},
	methods: {
		...mapActions(useAppStore, ['setDebug', 'setScrollbarWidth']),
		showPreloader() {
			if (this.preloader) {
				this.preloader.style.display = 'block';
				gsap.to(this.preloader, {
					opacity: 1,
					alpha: 1,
					duration: 1,
				});
			}
		},
		hidePreloader() {
			if ('scrollRestoration' in history) {
				history.scrollRestoration = 'manual';
			}

			if (document.documentElement.classList.contains('mobile-scroll')) {
				document.getElementById('app').scrollTop = 0;
			} else {
				window.scrollTo({ top: 0, behavior: 'instant' });
			}

			if (this.preloader) {
				gsap.to(this.preloader, {
					opacity: 0,
					alpha: 0,
					delay: 1,
					duration: 1,
					onComplete: () => {
						this.preloader.style.display = 'none';
					},
				});
			}
		},
	},
};
</script>
