import * as THREE from "three";
import Experience from "../Experience.js";
import Debug from "../Utils/Debug.js";
import State from "../State.js";
import Materials from "../Materials/Materials.js";
import * as helpers from "../Utils/Helpers.js";

import particlesVertexShader from "../Shaders/Particles/vertex.glsl";
import particlesFragmentShader from "../Shaders/Particles/fragment.glsl";
import Sizes from "@experience/Utils/Sizes.js";
import Input from "@experience/Utils/Input.js";
import * as math from "@experience/Utils/MathHelper.js";
import { MathUtils } from "three";
import gsap from "gsap";

export default class Particles {
    experience = Experience.getInstance();
    debug = Debug.getInstance();
    state = State.getInstance();
    materials = Materials.getInstance();
    sizes = Sizes.getInstance();
    input = Input.getInstance();
    scene = experience.scene;
    time = experience.time;
    timeline = experience.timeline;
    camera = experience.camera.instance;
    renderer = experience.renderer.instance;
    resources = experience.resources;
    container = new THREE.Group();

    sceneDepth = null

    particlesCount = 10000;
    normalizeScrollY = 0;
    normalizeScrollYTarget = 0;
    normalizePoints = [ 0.0, 0.3, 0.6 ];
    particleSize = 0.826;
    sectionCount = 9;
    range = 1.0 / this.sectionCount;
    //lambda = this.experience.isMobile ? 9 : 3
    lambda = 9

    flowsUp = experience.world.flowsUp
    flowsDown = experience.world.flowsDown
    particlesSimulation = experience.world.particlesSimulation
    cube = experience.world.cube

    particlesColors = [
        { color: new THREE.Color(0x143134), weight: 50 },
        { color: new THREE.Color(0x47947C), weight: 90 },
        { color: new THREE.Color(0xFFFFFF), weight: 0 }
    ];

    constructor() {
        // this.setModel();
        // this.setListeners();
        // this.setAnimation();
        this.updateFlows();
        this.setDebug();

        this.experience.world.on("ready", () =>{
            this.sceneDepth = experience.world.sceneDepth
        })
    }

    updateFlows() {
        this.flowsUp.material.uniforms.uSize.value = this.particleSize;
        this.flowsDown.material.uniforms.uSize.value = this.particleSize;

        for ( let i = 0; i < this.particlesCount; i++ ) {
            const color = helpers.getRandomColor( this.particlesColors );
            const i3 = i * 3;
            this.flowsUp.geometry.attributes.aColor.array[ i3 ] = color.r;
            this.flowsUp.geometry.attributes.aColor.array[ i3 + 1 ] = color.g;
            this.flowsUp.geometry.attributes.aColor.array[ i3 + 2 ] = color.b;
        }

        this.flowsUp.geometry.attributes.aColor.needsUpdate = true;

        for ( let i = 0; i < this.particlesCount; i++ ) {
            const color = helpers.getRandomColor( this.particlesColors );
            const i3 = i * 3;
            this.flowsDown.geometry.attributes.aColor.array[ i3 ] = color.r;
            this.flowsDown.geometry.attributes.aColor.array[ i3 + 1 ] = color.g;
            this.flowsDown.geometry.attributes.aColor.array[ i3 + 2 ] = color.b;
        }

        this.flowsDown.geometry.attributes.aColor.needsUpdate = true;
    }

    setModel() {

    }

    setListeners() {

    }

    resize() {

    }

    animation()
    {
        this.timeline.add(
            gsap.to(this.particlesSimulation.gpgpu.particlesVariable.material.uniforms.uProgress, {
                duration: 10,
                value: 1.0,
                ease: "linear",
                onUpdate: () => {
                    const progress = this.particlesSimulation.gpgpu.particlesVariable.material.uniforms.uProgress.value;
                    this.flowsDown.material.uniforms.uProgress.value = progress;
                    this.flowsUp.material.uniforms.uProgress.value = progress;
                },
                onComplete: () => {
                    this.scene.remove(this.flowsDown.points, this.flowsUp.points)

                    for (let i = this.sceneDepth.container.children.length - 1; i >= 0; i--) {
                        const object = this.sceneDepth.container.children[i];

                        if (object instanceof THREE.Points) {
                            this.sceneDepth.container.remove(object);
                        }

                        if ( object instanceof THREE.Group ) {
                            if ( object.children.length > 15 ) {
                                this.sceneDepth.container.remove(object);
                            }
                        }
                    }
                }
            }),
            "start",
        )

        let time = { value: 0 };

        this.timeline.add(
          gsap.to(time, {
              duration: 5,
              value: 1.0,
              ease: "linear",
              onUpdate: () => {

              },
              onComplete: () => {
                  window.dispatchEvent(new CustomEvent('3d-app:complete-first-animation'))
              }
          }),
          "start",
        )
    }

    setDebug() {
        if ( !this.debug.active ) return;

        this.debugFolder = this.debug.panel.addFolder( "Particles" );
        this.debugFolder.close();

        // add particle size
        this.debugFolder
            .add( this, "particleSize" )
            .min( 0 )
            .max( 3 )
            .step( 0.001 )
            .name( "Particle Size" )
            .onChange( () => {
                this.flowsUp.material.uniforms.uSize.value = this.particleSize;
                this.flowsDown.material.uniforms.uSize.value = this.particleSize;
            } );

        // add particles colors
        this.debugFolder
            .addColor( this.particlesColors[0], "color" )
            .name( "Particles Color 1" )
            .onChange( () => {
                this.changeColors();
            } );
        this.debugFolder
            .add( this.particlesColors[0], "weight" )
            .name( "% Random" )
            .onChange( () => {
                this.changeColors();
            } );
        this.debugFolder
            .addColor( this.particlesColors[1], "color" )
            .name( "Particles Color 2" )
            .onChange( () => {
                this.changeColors();
            } );
        this.debugFolder
            .add( this.particlesColors[1], "weight" )
            .name( "% Random" )
            .onChange( () => {
                this.changeColors();
            } );
        this.debugFolder
            .addColor( this.particlesColors[2], "color" )
            .name( "Particles Color 3" )
            .onChange( () => {
                this.changeColors();
            } );
        this.debugFolder
            .add( this.particlesColors[2], "weight" )
            .name( "% Random" )
            .onChange( () => {
                this.changeColors();
            } );

        // add 0 -> 1
        // this.debugFolder
        //     .add( this, "normalizeScrollY" )
        //     .min( 0 )
        //     .max( 1 )
        //     .step( 0.001 )
        //     .name( "Normalize Scroll Y" );
    }

    changeColors() {
        this.updateFlows()
    }

    setAnimation() {

    }

    update( deltaTime ) {
        // if (this.animation) this.animation.mixer.update(this.time.delta);
        //
        //
        //
        // this.normalizeScrollY = MathUtils.damp(this.normalizeScrollY, this.normalizeScrollYTarget, this.lambda, deltaTime);
        //
        //this.points.material.uniforms.uTime.value = this.time.elapsed;
        // this.points.material.uniforms.uCursor.value = this.input.cursor3D;
        // this.points.material.uniforms.uCursorDirection.value.set(
        //     this.input.cursorDirection.x,
        //     this.input.cursorDirection.y,
        //     this.input.cursorDirection.z
        // );
        // this.points.material.uniforms.uScroll.value = this.normalizeScrollY;


        this.updateScrollProgress()
    }

    updateScrollProgress() {

    }

}
