function register() {
	window.customElements.define(
		'scroll-wrapper',
		class extends HTMLElement {
			constructor() {
				super();

				this.onLoadListener = this.onLoadListener.bind(this);
				this.onResizeListener = this.onResizeListener.bind(this);
				this.onScrollListener = this.onScrollListener.bind(this);
				this.animateScroll = this.animateScroll.bind(this);
			}

			connectedCallback() {
				this.isScrollTranslated = this.hasAttribute('is-scroll-translated');
				this.attachShadow({ mode: 'open' });
				if (this.isScrollTranslated) {
					this.shadowRoot.innerHTML = `
						<style>
							.scroll-content {
								position: fixed; 
								top: 0; 
								left: 0; 
								width: 100vw;
								min-width: var(--scroll-content-min-width, 360px);
								will-change: transform;
								overflow: hidden;
							}
						</style>
						<div class="scroll-content">
							<slot></slot>
						</div>
						<div class="scroll-space"></div>
					`;
				} else {
					this.shadowRoot.innerHTML = `
						<div class="scroll-content">
							<slot></slot>
						</div>
					`;
				}

				this.smooth = this.hasAttribute('smooth');
				this.scrollerAttr = this.getAttribute('scroller');
				if (this.scrollerAttr) {
					this.scroller = document.querySelector(this.scrollerAttr);
				}
				this.intensity = 1;
				if (this.hasAttribute('intensity')) {
					const intensityAttrValue = this.getAttribute('intensity');
					this.intensity = isNaN(intensityAttrValue) ? 1 : Number(intensityAttrValue);
				}
				this.scrollY = 0;
				this.scrollX = 0;
				this.translateY = 0;
				this.scrollerScrollHeight = 0;
				this.animationFrameId = null;
				this.scrollContent = this.shadowRoot.querySelector('.scroll-content');
				if (this.isScrollTranslated) {
					this.scrollSpace = this.shadowRoot.querySelector('.scroll-space');
				}
				this.progressSteps = [];
				this.progressStepsElements = Array.from(this.querySelectorAll('[data-progress-step]'));

				if (document.readyState === 'complete') {
					if (this.isScrollTranslated) {
						this.setScrollSpaseHeight();
					}
					this.calcScrollHeight();
					this.calcProgress();
					this.calcProgressSteps();
					this.emitEventWithData('init');
				} else {
					window.addEventListener('load', this.onLoadListener);
				}

				window.addEventListener('resize', this.onResizeListener);

				const resizeObserver = new ResizeObserver((entries) => {
					for (let entry of entries) {
						const newHeight = entry.contentRect.height;

						if (newHeight !== entry.target.lastHeight) {
							entry.target.lastHeight = newHeight;
							this.onResizeListener();
						}
					}
				});

				resizeObserver.observe(this.scrollContent);

				if (this.scroller) {
					this.scroller.addEventListener('scroll', this.onScrollListener);
				} else {
					window.addEventListener('scroll', this.onScrollListener);
					window.addEventListener('hashchange', this.onHashChangeListener);
				}
			}

			static get observedAttributes() {
				return ['smooth'];
			}

			attributeChangedCallback(name) {
				if (name === 'smooth') {
					this.smooth = this.hasAttribute('smooth');
				}
			}

			disconnectedCallback() {
				window.removeEventListener('load', this.onLoadListener);
				window.removeEventListener('resize', this.onResizeListener);
				window.removeEventListener('scroll', this.onScrollListener);
				window.removeEventListener('hashchange', this.onHashChangeListener);
				if (this.scroller) {
					this.scroller.removeEventListener('scroll', this.onScrollListener);
				}
				window.cancelAnimationFrame(this.animationFrameId);
			}

			onLoadListener() {
				setTimeout(() => {
					if (this.isScrollTranslated) {
						this.setScrollSpaseHeight();
					}
					this.calcScrollHeight();
					this.calcProgress();
					this.calcProgressSteps();
					this.emitEventWithData('init');
				}, 0);
			}

			onResizeListener() {
				setTimeout(() => {
					if (this.isScrollTranslated) {
						this.setScrollSpaseHeight();
					}
					this.calcScrollHeight();
					this.calcProgress();
					this.calcProgressSteps();
					this.emitEventWithData('progresschange');
				}, 0);
			}

			onScrollListener() {
				window.cancelAnimationFrame(this.animationFrameId);
				if (this.scroller) {
					this.scrollY = this.scroller.scrollTop;
					this.scrollX = this.scroller.scrollLeft;
					this.scroll();
				} else {
					this.scrollY = window.scrollY;
					this.scrollX = window.scrollX;
					this.animationFrameId = requestAnimationFrame(this.animateScroll);
				}
			}

			onHashChangeListener(event) {
				const hash = window.location.hash;
				if (!hash) return;
				history.replaceState(null, null, ' ');
				const targetEl = document.querySelector(hash);
				if (!targetEl) return;
				const targetPosition = window.scrollY + targetEl.getBoundingClientRect().top - 100;
				requestAnimationFrame(() => {
					window.scrollTo({ top: targetPosition, behavior: 'smooth' });
				});
			}

			setScrollSpaseHeight() {
				this.scrollSpace.style.height = `${this.scrollContent.offsetHeight}px`;
			}

			scroll() {
				this.translateY = this.scrollY;
				this.calcProgress();
				this.emitEventWithData('progresschange');
			}

			animateScroll() {
				if (this.smooth) {
					const distance = this.scrollY - this.translateY;
					const absDistance = Math.abs(distance);

					if (absDistance > 0) {
						let step = (distance / absDistance) * Math.sqrt(absDistance / this.intensity);
						if (Math.abs(step) > absDistance) {
							step = distance;
						}

						this.translateY = this.translateY + step;
					}
				} else {
					this.translateY = this.scrollY;
				}

				this.scrollContent.style.transform = `translate(${-this.scrollX}px, ${-this.translateY}px)`;
				this.calcProgress();
				this.emitEventWithData('progresschange');

				if (this.translateY !== this.scrollY) {
					this.animationFrameId = requestAnimationFrame(this.animateScroll);
				}
			}

			calcScrollHeight() {
				if (this.scroller) {
					this.scrollerScrollHeight = this.scroller.scrollHeight;
				} else {
					this.scrollerScrollHeight = Math.max(
						document.body.scrollHeight,
						document.documentElement.scrollHeight,
						document.body.offsetHeight,
						document.documentElement.offsetHeight,
						document.body.clientHeight,
						document.documentElement.clientHeight,
					);
				}
			}

			calcProgress() {
				this.progress = this.translateY / (this.scrollerScrollHeight - document.documentElement.clientHeight);
			}

			calcProgressSteps() {
				this.progressSteps = this.progressStepsElements.map((item) => {
					return item.offsetTop / (this.scrollerScrollHeight - document.documentElement.clientHeight);
				});
			}

			emitEventWithData(event) {
				this.dispatchEvent(
					new CustomEvent(event, {
						detail: {
							progress: this.progress,
							translateX: this.scrollX,
							translateY: this.translateY,
							progressSteps: this.progressSteps,
						},
					}),
				);
			}
		},
	);
}

export { register };
