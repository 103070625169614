<template>
	<section :id="currentSection.id" class="our-clients">
		<div class="our-clients__container container">
			<div class="our-clients__top">
				<AppPagination :current-section="currentSection" :sections="sections" />
			</div>

			<template v-if="ourClients && ourClients.length">
				<template v-for="sliderIndex in 2" :key="sliderIndex">
					<div class="our-clients__slider">
						<div class="our-clients__slider-wrapper">
							<template v-for="duplicateSlidesIndex in 2" :key="duplicateSlidesIndex">
								<div v-for="(client, clientIndex) in ourClients" :key="clientIndex" class="our-clients__slide">
									<div class="our-clients__slide-wrapper">
										<img :src="client.image.src" alt="" />
									</div>
								</div>
							</template>
						</div>
					</div>
				</template>
			</template>
		</div>
	</section>
</template>

<script>
import AppPagination from '@/components/AppPagination.vue';
import ourClients from '@/data/our-clients.json';

export default {
	name: 'OurClients',
	components: { AppPagination },
	props: {
		currentSection: {
			type: Object,
			required: true,
		},
		sections: {
			type: Array,
			required: true,
		},
	},
	data: () => ({
		ourClients,
		stylesToInject: [
			`
			.swiper {
				overflow: visible
			}
			.swiper-wrapper {
				--swiper-wrapper-transition-timing-function: linear;
			}
			`,
		],
	}),
	computed: {},
};
</script>
