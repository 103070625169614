export default function getScrollbarWidth() {
	// Create the measurement node
	const scrollDiv = document.createElement('div');

	scrollDiv.style.top = '-9999px';
	scrollDiv.style.position = 'absolute';
	scrollDiv.style.width = '100px';
	scrollDiv.style.height = '100px';
	scrollDiv.style.overflow = 'scroll';

	document.body.appendChild(scrollDiv);

	// Get the scrollbar width
	const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;

	// Delete the DIV
	document.body.removeChild(scrollDiv);

	return scrollbarWidth;
}
