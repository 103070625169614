<template>
	<footer class="footer" id="contacts">
		<div class="footer__container container">
			<h2 class="footer__title m-0 center font-heading-secondary">Contact Us</h2>

			<div class="footer__content">
				<div class="footer__info">
					<p class="footer__info-text m-0 font-heading">
						For inquiries, reach out to us at<br />
						<a href="mailto:hello@saguni.studio">hello@saguni.studio</a>
					</p>

					<SocialIcons :items="socialNetworks" class="footer__social-icons" />
				</div>

				<div class="footer__form-wrapper">
					<h3 class="footer__form-title m-0 font-heading">How can we help you?</h3>

					<div class="footer__form-selector">
						<button
							v-for="form in forms"
							:key="form.id"
							:class="{ active: form === activeForm }"
							class="button button-outline-tertiary"
							@click="setActiveForm(form)"
						>
							{{ form.selectorLabel }}
						</button>
					</div>

					<template v-if="activeForm">
						<HelpForm v-if="activeForm.id === 'help'" :formClass="'footer__form'" />
						<JobApplicationForm v-if="activeForm.id === 'job-application'" :formClass="'footer__form'" />
						<QuestionForm v-if="activeForm.id === 'question'" :formClass="'footer__form'" />
					</template>
				</div>
			</div>

			<div class="footer__bottom">
				<p class="footer__copyright m-0 uppercase">{{ `© ${getFullYear()} Saguni Studios. All Rights Reserved.` }}</p>

				<ul class="footer__menu list-unstyled">
					<li v-for="(item, index) in menu" :key="index" class="header__menu-item">
						<RouterLink v-if="item.to" :to="item.to" class="footer__menu-link uppercase">{{ item.title }}</RouterLink>
						<a v-else :href="item.url" :target="item.target" class="footer__menu-link uppercase">{{ item.title }}</a>
					</li>
				</ul>
			</div>
		</div>
	</footer>
</template>

<script>
import socialNetworks from '@/data/social-networks.json';
import SocialIcons from '@/components/SocialIcons.vue';
import menus from '@/data/menus.json';
import HelpForm from '@/components/forms/HelpForm.vue';
import JobApplicationForm from '@/components/forms/JobApplicationForm.vue';
import QuestionForm from '@/components/forms/QuestionForm.vue';

export default {
	name: 'AppFooter',
	components: { QuestionForm, JobApplicationForm, HelpForm, SocialIcons },
	data: () => ({
		socialNetworks,
		menu: menus.footer_menu,
		activeForm: null,
		forms: [
			{ id: 'help', selectorLabel: 'I need help' },
			{ id: 'job-application', selectorLabel: 'Job Applications' },
			{ id: 'question', selectorLabel: 'something else' },
		],
	}),
	mounted() {
		this.setActiveForm(this.forms[0]);
	},
	methods: {
		setActiveForm(form) {
			this.activeForm = form;
		},
		getFullYear() {
			const date = new Date();
			return date.getFullYear();
		},
	},
};
</script>
