<template>
	<section class="faq">
		<div class="container">
			<div class="faq__wrapper">
				<div class="faq__collapses">
					<FaqCollapse v-for="(item, index) in faqData" :key="index" :index="index" :faqItemData="item"/>
				</div>

				<h1 class="faq__title c-primary font-heading-secondary uppercase m-0">
					FAQ
				</h1>
			</div>
		</div>
	</section>
</template>

<script>
import faq from '@/data/faq.json';
import FaqCollapse from '@/components/FaqView/FaqCollapse.vue';

export default {
	name: 'FaqSection',
	components: { FaqCollapse },
	data: () => ({
		faqData: faq
	}),
};
</script>
