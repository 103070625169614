<template>
	<scroll-wrapper :smooth="isScrollAnimated" :scroller="scroller" :is-scroll-translated="isScrollTranslated" intensity="1" @init="onInit" @progresschange="onProgressChange">
		<main>
			<slot></slot>
		</main>

		<AppFooter v-if="footerVisible" data-progress-step />
	</scroll-wrapper>
</template>
<script>
import AppFooter from '@/components/AppFooter.vue';
import { mapActions, mapState } from 'pinia';
import { useAppStore } from '@/stores/app.js';
import { isMobile } from '@/helpers/isMobile';

export default {
	name: 'AppLayout',
	components: { AppFooter },
	data: () => ({
		scrollY: 0,
		translateY: 0,
		animationFrameId: null,
		scroller: null,
		isScrollTranslated: true,
	}),
	props: {
		footerVisible: {
			type: Boolean,
			default: true
		}
	},
	computed: {
		...mapState(useAppStore, ['isScrollAnimated', 'isCircleAnimation'])
	},
	created() {
		if (isMobile.any()) {
			this.scroller = '#app';
			this.isScrollTranslated = null;
			document.documentElement.classList.add('mobile-scroll')
		}
	},
	methods: {
		...mapActions(useAppStore, ['setCustomScrollParams', 'setScrollAnimated']),
		onInit(event) {
			const { translateX, translateY, progress, progressSteps } = event.detail;

			this.setCustomScrollParams({ translateX, translateY, progress, progressSteps });

			if (!this.isCircleAnimation) {
				window.dispatchEvent(
					new CustomEvent('app:normalized-scroll-y', {
						detail: {
							...event.detail,
						},
					}),
				);
			}
		},
		onProgressChange(event) {
			const { translateX, translateY, progress, progressSteps } = event.detail;

			this.setCustomScrollParams({ translateX, translateY, progress, progressSteps });

			if (!this.isCircleAnimation) {
				window.dispatchEvent(
					new CustomEvent('app:normalized-scroll-y', {
						detail: {
							...event.detail,
						},
					}),
				);
			}
		},
	},
};
</script>
