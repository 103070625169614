import * as THREE from 'three'
import gsap from 'gsap'
import * as helpers from "../Utils/Helpers.js";

import Experience from '@experience/Experience.js'
import vertexShader from '@experience/Shaders/Flows/vertex.glsl'
import fragmentShader from '@experience/Shaders/Flows/fragment.glsl'

export default class Flows
{
    particlesColors = [
        { color: new THREE.Color(0x143134), weight: 50 },
        { color: new THREE.Color(0x47947C), weight: 90 },
        { color: new THREE.Color(0xFFFFFF), weight: 0 }
    ];

    constructor(_options)
    {
        this.experience = new Experience()
        this.sizes = this.experience.sizes
        this.resources = this.experience.resources
        this.scene = this.experience.scene
        this.renderer = this.experience.renderer
        this.sound = this.experience.world.sound
        this.time = this.experience.time
        this.debug = this.experience.debug
        this.timeline = this.experience.timeline

        this.count = 2000

        this.pointA = _options.pointA
        this.pointB = _options.pointB
        this.controlPoint1 = _options.controlPoint1
        this.controlPoint2 = _options.controlPoint2
        this.uSpeed = _options.uSpeed
        this.uPerlinMultiplier = _options.uPerlinMultiplier
        this.uPerlinFrequency = _options.uPerlinFrequency
        this.uTimeFrequency = _options.uTimeFrequency

        this.setColors()
        this.setPositions()
        this.setGeometry()
        this.setMaterial()
        this.setPoints()

        this.setDebug()
    }

    reset()
    {
        this.geometry.dispose()

        this.setPositions()
        this.setGeometry()

        this.points.geometry = this.geometry
    }

    setColors()
    {
        this.colors = new Float32Array(this.count * 3)

        let i = 0


        for(; i < this.count; i++)
        {

            const color = helpers.getRandomColor( this.particlesColors );

            this.colors[i * 3 + 0] = color.r
            this.colors[i * 3 + 1] = color.g
            this.colors[i * 3 + 2] = color.b
            this.colors[i * 3 + 3] = Math.random();
        }
    }

    setPositions()
    {
        this.positions = new Float32Array(this.count * 3)

        let i = 0


        for(; i < this.count; i++)
        {
            const azimuthalAngle = Math.random() * Math.PI * 2 // Угол вокруг Y
            const polarAngle = Math.acos(2 * Math.random() - 1) // Угол от оси Y

            const radius = Math.cbrt(Math.random()) * 0.7 // Используем корень третьей степени, чтобы равномерно распределить точки по объему
            //const radius = 10000;

            this.positions[i * 3 + 0] = Math.sin(polarAngle) * Math.cos(azimuthalAngle) * radius + Math.random() * 0.2
            this.positions[i * 3 + 1] = Math.sin(polarAngle) * Math.sin(azimuthalAngle) * radius + Math.random() * 0.2
            this.positions[i * 3 + 2] = Math.cos(polarAngle) * radius + Math.random() * 0.2
        }
    }


    setGeometry()
    {
        const sizes = new Float32Array(this.count)
        const startTime = new Float32Array(this.count)

        for(let i = 0; i < this.count; i++)
        {
            sizes[i] = 0.2 + Math.random() * 0.8
            startTime[i] = Math.random()

        }

        this.geometry = new THREE.BufferGeometry()
        this.geometry.setAttribute('position', new THREE.BufferAttribute(this.positions, 3))
        this.geometry.setAttribute('aColor', new THREE.BufferAttribute(this.colors, 3))
        this.geometry.setAttribute('aSize', new THREE.BufferAttribute(sizes, 1))
        this.geometry.setAttribute('aStartTime', new THREE.BufferAttribute(startTime, 1))
    }

    setMaterial()
    {
        this.material = new THREE.ShaderMaterial({
            transparent: true,
            blending: THREE.AdditiveBlending,
            depthWrite: false,
            uniforms:
                {
                    uResolution: new THREE.Uniform(
                        new THREE.Vector2(
                            this.sizes.width * this.sizes.pixelRatio,
                            this.sizes.height * this.sizes.pixelRatio
                        )
                    ),
                    uTime: { value: 0 },
                    uDelta: { value: 16 },
                    uSize: { value: 0.5 },
                    uSeed: { value: Math.random() },

                    uSpeed: { value: this.uSpeed },
                    uPerlinMultiplier: { value: this.uPerlinMultiplier },
                    uPerlinFrequency: { value: this.uPerlinFrequency },
                    uTimeFrequency: { value: this.uTimeFrequency },

                    uPointA: { value: this.pointA },
                    uPointB: { value: this.pointB },
                    uControlPoint1: { value: this.controlPoint1 },
                    uControlPoint2: { value: this.controlPoint2 },

                    uParticlesTexture: new THREE.Uniform(),
                    uProgress: new THREE.Uniform(0.0)
                },
            vertexShader: vertexShader,
            fragmentShader: fragmentShader
        })

    }

    setPoints()
    {
        this.points = new THREE.Points(this.geometry, this.material)
        this.points.frustumCulled = false
        this.scene.add(this.points)
    }

    animation()
    {
        this.timeline.add(
            gsap.from(this.material.uniforms.uOpacity, {
                duration: 10,
                value: 0.0,
                ease: "linear",
            }),
            "start"
        )
    }

    setDebug()
    {
        // if(this.debug.active)
        // {
        //
        //     this.debugFolder = this.debug.panel.addFolder( "Flow Field" );
        //     this.debugFolder.add(this.material.uniforms.uSpeed, 'value').min(0.001).max(1).step(0.001).name('uSpeed')
        //     this.debugFolder.add(this.material.uniforms.uPerlinMultiplier, 'value').min(0.001).max(5).step(0.001).name('uPerlinMultiplier')
        //     this.debugFolder.add(this.material.uniforms.uPerlinFrequency, 'value').min(0.001).max(5).step(0.001).name('uPerlinFrequency')
        //     this.debugFolder.add(this.material.uniforms.uTimeFrequency, 'value').min(0.001).max(5).step(0.001).name('uTimeFrequency')
        // }
    }

    update()
    {
        this.material.uniforms.uTime.value = this.time.elapsed
        this.material.uniforms.uDelta.value = this.time.delta
    }
}
