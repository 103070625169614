<template>
	<AppModal
		v-bind="$attrs"
		class="video-modal video-modal--local"
		content-transition="vfm-slide-down"
		@opened="onOpened"
		@closed="onClosed"
	>
		<div class="modal__main">
			<div class="video-wrapper self-hosted-video">
				<video ref="localVideo" disablePictureInPicture controls playsinline :poster="poster" loop="loop">
					<source :src="url" type="video/mp4" />
				</video>
			</div>
		</div>
	</AppModal>
</template>

<script>
import AppModal from '@/components/AppModal.vue';

export default {
	name: 'LocalVideoModal',
	props: {
		videoURl: {
			type: String,
			required: true,
		},
		posterURL: {
			type: String,
			required: true,
		},
	},
	components: {
		AppModal,
	},
	data() {
		return {
			url: null,
			poster: null,
		};
	},
	watch: {
		videoURl(newURL) {
			if (newURL) {
				this.url = newURL;
			}
		},
		posterURL(newURL) {
			if (newURL) {
				this.poster = newURL;
			}
		},
	},
	methods: {
		onOpened() {
			if (this.$refs.localVideo) {
				this.$refs.localVideo.play();
			}
		},
		onClosed() {
			if (this.$refs.localVideo) {
				this.$refs.localVideo.pause();
				this.$refs.localVideo = 0;
			}
		},
	},
};
</script>
