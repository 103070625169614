<template>
	<section class="privacy-policy">
		<div class="container">
			<div class="privacy-policy__wrapper">
				<h1 class="privacy-policy__title c-primary font-heading-secondary uppercase m-0">
					Privacy Policy
				</h1>

				<div class="privacy-policy__text-content">
					<h2>
						Effective Date
					</h2>

					<p>
						1st April 2023
					</p>

					<p>
						SaGuNi Tech LLC is committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and protect the information obtained through our platform, DriveSafe. By using the App, you consent to the practices described in this Privacy Policy.
					</p>

					<h3>
						Information We Collect
					</h3>

					<p>
						1.1 Personal Information: We may collect personal information that you provide to us voluntarily when using the App, such as your name, email address, and phone number. We may also collect your location information while the App is in use for navigation and related purposes.
					</p>

					<p>
						1.2 Device Information: We may collect certain information about your mobile device, including its unique device identifier, operating system, and mobile network information. This information is collected automatically when you use the App.
					</p>

					<p>
						1.3 Usage Information: We may collect information about your interactions with the App, such as the features you use, the pages you visit, and the actions you take while using the App. This data helps us improve the App and provide a better user experience.
					</p>

					<p>
						1.4 Location Information: DriveSafe uses GPS and other technologies to determine your device's location. This information helps us provide you with accurate and real-time navigation services. We collect your precise GPS location, including latitude, longitude, and altitude, to offer the best possible navigation experience.
					</p>

					<h3>
						Use of Information
					</h3>

					<p>
						2.1 Providing and Improving the App: We use the information we collect to operate and maintain the App, provide customer support, and enhance its functionality and performance. This includes using your location information to provide accurate navigation services.
					</p>

					<p>
						2.2 Communications: We may use your contact information to send you important notifications and updates about the App, respond to your inquiries, and provide you with relevant information or promotional materials. You can opt-out of receiving these communications at any time.
					</p>

					<p>
						2.3 Aggregated Data: We may aggregate and anonymize the information collected from users to generate statistical or analytical insights. This aggregated data does not personally identify you and may be used for research, analysis, marketing, or other legitimate business purposes.
					</p>

					<p>
						2.4 Navigation Services: The primary purpose of collecting your location data is to provide you with turn-by-turn navigation and route guidance. By accessing your real-time location, DriveSafe can calculate the most efficient routes, estimate arrival times, and offer relevant traffic updates to enhance your driving experience.
					</p>

					<p>
						2.5 Location Sharing: DriveSafe may offer a feature that allows you to share your location with friends, family, or other users within the app. However, this feature is entirely optional, and you have full control over whether and with whom you wish to share your location.
					</p>

					<p>
						2.6 Service Improvement: We may use aggregated and anonymized location data to analyse usage patterns, identify areas for improvement, and enhance the overall performance of DriveSafe. Please note that this data is stripped of any personally identifiable information.
					</p>

					<h3>
						Sharing of Information
					</h3>

					<p>
						3.1 Service Providers: We may share your information with trusted third-party service providers who assist us in operating the App, analysing data, or providing other services necessary for our business. These service providers are obligated to maintain the confidentiality and security of your information.
					</p>

					<p>
						3.2 Legal Requirements: We may disclose your information if required by law, regulation, legal process, or governmental request. We may also disclose information to enforce our rights, investigate fraud or other illegal activities, or protect the rights, property, or safety of our users or others.
					</p>

					<p>
						3.3 Business Transfers: In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred to the acquiring entity as part of the transaction. We will notify you of any such change in ownership or control of your personal information.
					</p>
					
					<h3>
						Data Security
					</h3>

					<p>
						We take reasonable measures to protect your personal information from unauthorised access, use, or disclosure. However, no method of transmission over the internet or electronic storage is 100% secure. Therefore, while we strive to use commercially acceptable means to protect your information, we cannot guarantee its absolute security.
					</p>

					<p>
						We take the security of your location information seriously. We implement industry-standard security measures to protect your data from unauthorised access, disclosure, or alteration. Access to your location data is restricted to authorised personnel only.
					</p>

					<p>
						We retain your location information only for as long as necessary to fulfil the purposes outlined in this Privacy Policy, or as required by applicable laws and regulations. Once your data is no longer needed, we will securely dispose of it.
					</p>

					<h3>
						Children's Privacy
					</h3>

					<p>
						The App is not intended for use by individuals under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have collected personal information from a child under 13, we will take steps to delete it. If you believe we may have collected personal information from a child under 13, please contact us at the email address provided below.
					</p>

					<h3>
						Changes to the Privacy Policy
					</h3>

					<p>
						We reserve the right to modify or update this Privacy Policy at any time. Any changes will be effective upon posting the revised Privacy Policy in the App. We encourage you to review this Privacy Policy periodically for any updates.
					</p>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: 'PrivacyPolicyContent',
};
</script>
