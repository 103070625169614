<template>
	<canvas :class="{ active: debug }" id="three-canvas"></canvas>
</template>

<script>
import { initScene } from '@/three/index.js';
import { mapState } from 'pinia';
import { useAppStore } from '@/stores/app.js';

export default {
	name: 'ThreeCanvas',
	computed: {
		...mapState(useAppStore, ['debug']),
	},
	mounted() {
		this.$nextTick(() => {
			initScene({ canvas: this.$el });
		});
	},
};
</script>
