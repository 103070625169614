import * as THREE from "three";
import Experience from "../Experience.js";
import Debug from "../Utils/Debug.js";
import State from "../State.js";
import { GPUComputationRenderer } from "three/addons/misc/GPUComputationRenderer.js";
import gpgpuParticlesShader from "../Shaders/Gpgpu/particles.glsl";
import { createNoise4D } from 'simplex-noise';
import gsap from "gsap";

export default class ParticlesSimulation {
    experience = Experience.getInstance()
    debug = Debug.getInstance()
    state = State.getInstance()
    scene = experience.scene
    time = experience.time
    camera = experience.camera.instance
    renderer = experience.renderer.instance
    resources = experience.resources
    timeline = this.experience.timeline
    container = new THREE.Group();

    //points = experience.world.particles.points;
    flowsUp = experience.world.flowsUp;
    flowsDown = experience.world.flowsDown;

    constructor() {
        this.setModel();
        this.setDebug();

        // disable block with class app-header
        // document.querySelector('.app-header').style.display = 'none';
    }

    setModel() {
        /**
         * Base geometry
         */

        const baseGeometry = {};
        //baseGeometry.instance = this.points.geometry;
        baseGeometry.count = 10000;

        /**
         * GPU Compute
         */

        // Setup
        const gpgpu = {};
        this.gpgpu = gpgpu;
        gpgpu.size = Math.ceil( Math.sqrt( baseGeometry.count ) ) / 5.0;
        gpgpu.computation = new GPUComputationRenderer( gpgpu.size, gpgpu.size, this.renderer );

        // Base particles
        const baseParticlesTexture = gpgpu.computation.createTexture();
        const noise4D = createNoise4D();

        for(let i = 0; i < baseGeometry.count; i++)
        {
            const i3 = i * 3
            const i4 = i * 4


            // Position sphere
            //const radius = 0.3
            // random radius 0 -> 0.3

            const radius = Math.random() * 0.3

            const theta = Math.random() * Math.PI * 2
            const phi = Math.acos(2 * Math.random() - 1)

            const x = radius * Math.sin(phi) * Math.cos(theta)
            let y = radius * Math.sin(phi) * Math.sin(theta)
            const z = radius * Math.cos(phi)

            // random add + 1 or - 1 to y
            //y = Math.random() < 0.5 ? y + 1 : y - 1

            let position = new THREE.Vector3(x, y, z)
            // rotate position
            //position.applyAxisAngle(new THREE.Vector3(0, 0, -1), Math.PI / 2)

            // // Position based on geometry
            // baseParticlesTexture.image.data[i4 + 0] = x + noise4D( x, y, z, this.time.elapsed) * 1
            // baseParticlesTexture.image.data[i4 + 1] = y + noise4D( x, y, z, this.time.elapsed) * 1
            // baseParticlesTexture.image.data[i4 + 2] = z + noise4D( x, y, z, this.time.elapsed) * 1
            // baseParticlesTexture.image.data[i4 + 3] = Math.random()

            baseParticlesTexture.image.data[i4 + 0] = position.x
            baseParticlesTexture.image.data[i4 + 1] = position.y
            baseParticlesTexture.image.data[i4 + 2] = position.z
            baseParticlesTexture.image.data[i4 + 3] = Math.random()
        }

        // Particles variable
        gpgpu.particlesVariable = gpgpu.computation.addVariable('uParticles', gpgpuParticlesShader, baseParticlesTexture)
        gpgpu.computation.setVariableDependencies(gpgpu.particlesVariable, [ gpgpu.particlesVariable ])

        // Uniforms
        gpgpu.particlesVariable.material.uniforms.uTime = new THREE.Uniform(0)
        gpgpu.particlesVariable.material.uniforms.uDeltaTime = new THREE.Uniform(0)
        gpgpu.particlesVariable.material.uniforms.uBase = new THREE.Uniform(baseParticlesTexture)
        gpgpu.particlesVariable.material.uniforms.uFlowFieldInfluence = new THREE.Uniform(0.5)
        gpgpu.particlesVariable.material.uniforms.uFlowFieldStrength = new THREE.Uniform(1.222)
        gpgpu.particlesVariable.material.uniforms.uFlowFieldFrequency = new THREE.Uniform(0.5)
        gpgpu.particlesVariable.material.uniforms.uProgress = new THREE.Uniform(0.0)

        // Init
        gpgpu.computation.init()

        // Set Geometry UVs
        const particlesUvArray = new Float32Array(baseGeometry.count * 2)

        for(let i = 0; i < baseGeometry.count; i++)
        {
            const i2 = i * 2

            // Particles UV
            const y = Math.floor(i / gpgpu.size)
            const x = i - y * gpgpu.size

            particlesUvArray[i2 + 0] = (x + 0.5) / gpgpu.size
            particlesUvArray[i2 + 1] = (y + 0.5) / gpgpu.size
        }

        // this.points.geometry.setAttribute('aParticlesUv', new THREE.BufferAttribute(particlesUvArray, 2))
        // this.points.geometry.needsUpdate = true

        this.flowsUp.geometry.setAttribute('aParticlesUv', new THREE.BufferAttribute(particlesUvArray, 2))
        this.flowsUp.geometry.needsUpdate = true

        this.flowsDown.geometry.setAttribute('aParticlesUv', new THREE.BufferAttribute(particlesUvArray, 2))
        this.flowsDown.geometry.needsUpdate = true
    }

    resize() {

    }

    setDebug() {
        if ( !this.debug.active ) return;

        this.debugFolder = this.debug.panel.addFolder( "Flow Field" );
        this.debugFolder.close()

        this.debugFolder.add(this.gpgpu.particlesVariable.material.uniforms.uFlowFieldInfluence, 'value')
            .min(0).max(1).step(0.001).name('uFlowfieldInfluence')
        this.debugFolder.add(this.gpgpu.particlesVariable.material.uniforms.uFlowFieldStrength, 'value')
            .min(0).max(10).step(0.001).name('uFlowfieldStrength')
        this.debugFolder.add(this.gpgpu.particlesVariable.material.uniforms.uFlowFieldFrequency, 'value')
            .min(0).max(1).step(0.001).name('uFlowfieldFrequency')

        this.debugFolder.add(this.gpgpu.particlesVariable.material.uniforms.uProgress, 'value')
            .min(0).max(1).step(0.01).name('uProgress').onChange(() => {
            this.flowsUp.material.uniforms.uProgress.value = this.gpgpu.particlesVariable.material.uniforms.uProgress.value
            this.flowsDown.material.uniforms.uProgress.value = this.gpgpu.particlesVariable.material.uniforms.uProgress.value
        })


        //this.debug.createDebugTexture( this.gpgpu.computation.getCurrentRenderTarget(this.gpgpu.particlesVariable).texture )

        //this.debug.createDebugTexture( this.resources.items.displacementTexture )
    }

    update( deltaTime ) {
        // GPGPU Update
        this.gpgpu.particlesVariable.material.uniforms.uTime.value = this.time.elapsed
        this.gpgpu.particlesVariable.material.uniforms.uDeltaTime.value = deltaTime
        this.gpgpu.computation.compute()

        //this.points.material.uniforms.uParticlesTexture.value = this.gpgpu.computation.getCurrentRenderTarget(this.gpgpu.particlesVariable).texture

        //particles.material.uniforms.uParticlesTexture.value = gpgpu.computation.getCurrentRenderTarget(gpgpu.particlesVariable).texture

        this.flowsUp.material.uniforms.uParticlesTexture.value = this.gpgpu.computation.getCurrentRenderTarget(this.gpgpu.particlesVariable).texture
        this.flowsDown.material.uniforms.uParticlesTexture.value = this.gpgpu.computation.getCurrentRenderTarget(this.gpgpu.particlesVariable).texture
    }

}
