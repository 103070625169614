<template>
	<section class="service-section">
		<div class="container">
			<h1 class="service-section__title c-primary font-heading-secondary uppercase m-0">
				Services
			</h1>

			<swiper-container
      			ref="swiperRef"
				slides-per-view="auto"
      			:pagination="{ el: '.service-section__pagination', clickable: true }"
				space-between="20"
				speed="500"
				:injectStyles="swiperStyles"
      			@slideChange="onSlideChange"
			>
				<swiper-slide  v-for="(item, index) in services" :key="index">
					<div class="service-section__card">
						<span class="service-section__card-number font-heading-secondary c-primary">
							{{ (index + 1) < 10 ? '0' + (index + 1) : (index + 1) }} /
						</span>

						<span class="service-section__card-title font-heading uppercase m-0">
							{{ item.title }}
						</span>

						<p class="service-section__card-description m-0">
							{{ item.text }}
						</p>
					</div>
				</swiper-slide>
			</swiper-container>

			<div class="service-section__pagination"></div>
		</div>
	</section>
</template>

<script>
import services from '@/data/services.json';

export default {
	name: 'ServiceSection',
	data: () => ({
		services: services,
		swiperStyles: ['.swiper {overflow: visible;}']
	}),
	mounted() {
		this.$nextTick(() => {
			const swiper = this.$refs.swiperRef.swiper;
			swiper.on('slideChange', this.onSlideChange);
		});
	},
	methods: {
		onSlideChange() {
			const swiper = this.$refs.swiperRef.swiper;
			const activeIndex = swiper.activeIndex;
			const paginationContainer = document.querySelector('.service-section__pagination');
			const bullets = paginationContainer.querySelectorAll('.swiper-pagination-bullet');
			const activeBullet = bullets[activeIndex];

			if (activeBullet) {
				const containerRect = paginationContainer.getBoundingClientRect();
				const bulletRect = activeBullet.getBoundingClientRect();

				const containerScrollLeft = paginationContainer.scrollLeft;
				const bulletOffsetLeft = bulletRect.left - containerRect.left + containerScrollLeft;

				const containerCenter = containerRect.width / 2;
				const bulletCenter = bulletRect.width / 2;

				paginationContainer.scrollTo({
					left: bulletOffsetLeft - containerCenter + bulletCenter,
					behavior: 'smooth'
				});
			}
		}
	},
};
</script>
