<template>
	<div>
		<VueFinalModal
			v-bind="$attrs"
			:zIndex-fn="() => 10"
			teleport-to="body"
			class="modal"
			overlay-class="modal__overlay"
			overlay-transition="vfm-fade"
			content-class="modal__content"
			content-transition="vfm-fade"
		>
			<div class="modal__wrapper" @click.self="onClick">
				<div class="modal__body">
					<button class="modal__close" @click="onClick" aria-label="Close Modal">
						<IconClose />
					</button>

					<slot></slot>
				</div>
			</div>
		</VueFinalModal>
	</div>
</template>

<script>
import { VueFinalModal } from 'vue-final-modal';
import IconClose from '@/components/icons/IconClose.vue';

export default {
	name: 'AppModal',
	components: {
		VueFinalModal,
		IconClose,
	},
	methods: {
		onClick() {
			this.$vfm.close(this.$attrs['modal-id']);
		},
	},
};
</script>
