import { defineStore } from 'pinia';

export const useAppStore = defineStore('app', {
	state: () => ({
		debug: false,
		scrollbarWidth: 0,
		customScrollTranslateX: 0,
		customScrollTranslateY: 0,
		customScrollProgress: 0,
		customScrollProgressSteps: null,
		gridScrollIsEnabled: true,
		isHeroAnimationPlayed: false,
		isHeaderAnimationPlayed: false,
		isScrollAnimated: true,
		isCircleAnimation: false,
	}),
	actions: {
		setDebug(value) {
			this.debug = value;
		},
		setScrollbarWidth(value) {
			this.scrollbarWidth = value;
		},
		setCustomScrollParams({ translateX = 0, translateY = 0, progress = 0, progressSteps }) {
			this.customScrollTranslateX = translateX;
			this.customScrollTranslateY = translateY;
			this.customScrollProgress = progress;
			this.customScrollProgressSteps = progressSteps;
		},
		toggleGridScroll(value) {
			this.gridScrollIsEnabled = value;
		},
		setHeroAnimationPlayed(value) {
			this.isHeroAnimationPlayed = value;
		},		
		setHeaderAnimationPlayed(value) {
			this.isHeaderAnimationPlayed = value;
		},
		setScrollAnimated(value) {
			this.isScrollAnimated = value;
		},
		setCircleAnimation(value) {
			this.isCircleAnimation = value;
		}
	},
});
