<template>
	<AppLayout>
		<PrivacyPolicyContent data-progress-step />
	</AppLayout>
</template>

<script>
import AppLayout from '@/components/AppLayout.vue';
import PrivacyPolicyContent from '@/components/PrivacyPolicyView/PrivacyPolicyContent.vue';

export default {
	name: 'PrivacyPolicyView',
	components: { AppLayout, PrivacyPolicyContent },
};
</script>
