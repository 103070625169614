<template>
	<ul class="social-icons list-unstyled">
		<li v-for="(item, index) in items" :key="index" class="social-icons__item">
			<a :href="item.url" :aria-label="item.title" class="social-icons__link" target="_blank">
				<IconInstagram v-if="item.title === 'Instagram'" class="icon" aria-hidden="true" />
				<IconX v-else-if="item.title === 'X'" class="icon" aria-hidden="true" />
				<IconLinkedin v-else-if="item.title === 'Linkedin'" class="icon" aria-hidden="true" />
			</a>
		</li>
	</ul>
</template>

<script>
import IconInstagram from '@/components/icons/IconInstagram.vue';
import IconX from '@/components/icons/IconX.vue';
import IconLinkedin from '@/components/icons/IconLinkedin.vue';

export default {
	name: 'SocialIcons',
	components: { IconLinkedin, IconX, IconInstagram },
	props: {
		items: {
			type: Array,
			required: true,
		},
	},
};
</script>
