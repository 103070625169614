uniform float uTime;
uniform float uDeltaTime;
uniform sampler2D uBase;
uniform float uFlowFieldInfluence;
uniform float uFlowFieldStrength;
uniform float uFlowFieldFrequency;
uniform float uProgress;

#include ../Includes/simplexNoise3d.glsl
#include ../Includes/simplexNoise4d.glsl

float inOutProgress(vec3 position, vec3 target, float scrollProgress) {
    // Mixed position
    float noiseOrigin = simplexNoise3d(position * 0.2);
    float noiseTarget = simplexNoise3d(target * 0.2);
    float noise = mix(noiseOrigin, noiseTarget, scrollProgress);
    noise = smoothstep(-1.0, 1.0, noise);

    float duration = 0.3;
    float delay = (1.0 - duration) * noise;
    float end = delay + duration;
    float progress = smoothstep(delay, end, scrollProgress);

    return progress;
}

float remap(float value, float inputMin, float inputMax, float outputMin, float outputMax) {
    return outputMin + ((value - inputMin) / (inputMax - inputMin) * (outputMax - outputMin));
}

vec2 rotate2d(vec2 _st, float _angle){
    _st -= 0.5;
    _st =  mat2(cos(_angle),-sin(_angle),
    sin(_angle),cos(_angle)) * _st;
    _st += 0.5;
    return _st;
}

void main()
{
    //float time = uTime * 0.2;
    //float time = clamp(uTime * 0.7, 0.0, 10.0);
    float time = clamp(uProgress * 10.0, 0.0, 10.0);

    vec2 uv = gl_FragCoord.xy / resolution.xy;
    vec4 particle = texture(uParticles, uv);
    vec4 base = texture(uBase, uv);

    // Dead
    if(particle.a >= 1.0)
    {
        if(time < 4.0){
            particle.a = mod(particle.a, 1.0);
            particle.xyz = base.xyz;
        }
    }

    // Alive
    else
    {
        // Strength
        float strength = simplexNoise4d(vec4(base.xyz * 0.2, time + 1.0));
        float influence = (uFlowFieldInfluence - 0.5) * (- 2.0);
        strength = smoothstep(influence, 1.0, strength);

        // Flow field
        vec3 flowField = vec3(
            simplexNoise4d(vec4(particle.xyz * uFlowFieldFrequency + 0.0, time)),
            simplexNoise4d(vec4(particle.xyz * uFlowFieldFrequency + 1.0, time)),
            simplexNoise4d(vec4(particle.xyz * uFlowFieldFrequency + 2.0, time))
        );
        flowField = normalize(flowField);
        particle.xyz += flowField * uDeltaTime * uFlowFieldStrength /* * strength */;


        //particle.xyz += uDeltaTime * uFlowFieldStrength /* * strength */;


//        vec3 tmpPos = particle.xyz + uDeltaTime * uFlowFieldStrength;
//
//        float inOutProgress = inOutProgress(particle.xyz ,tmpPos.xyz, uProgress);// Mixed transform
//        vec3 mixedPosition = mix(tmpPos, particle.xyz, inOutProgress);

        //particle.xyz = mixedPosition;
        //particle.xyz += uDeltaTime * uFlowFieldStrength /* * strength */;

        // flow to center

//        if( particle.y > 0.0 ) {
//            particle.y -= uDeltaTime * 0.1;
//        } else {
//            particle.y += uDeltaTime * 0.1;
//        }


        // Decay
        particle.a += uDeltaTime * 0.4;
    }

    gl_FragColor = particle;
}
