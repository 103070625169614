import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import FaqView from '../views/FaqView.vue';
import PrivacyPolicyView from '../views/PrivacyPolicyView.vue';
import AboutUsView from '../views/AboutUsView.vue';
import Experience from '@experience/Experience.js';

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: [
		{
			path: '/faq',
			name: 'FAQ',
			component: FaqView,
		},
		{
			path: '/privacy-policy',
			name: 'Privacy Policy',
			component: PrivacyPolicyView,
		},
		{
			path: '/about-us',
			name: 'About Us',
			component: AboutUsView,
		},
		{
			path: '/',
			name: 'home',
			component: HomeView,
		},
	],
});

let experience = null;

window.addEventListener('3d-app:ready', () => {
	experience = Experience.getInstance();
});

router.beforeEach((to, from) => {
	if (to.fullPath.split('#')[0] === from.fullPath.split('#')[0]) {
		return;
	}

	if (to.name !== 'home' && experience) {
		experience?.destroy();
		document.getElementById('three-canvas')?.remove();
		document.getElementById('screenshot-canvas')?.remove();
	} else if (to.name === 'home' && experience) {
		window.location.href = '/';
	}
});

export default router;
