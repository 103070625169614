#define STANDARD

varying vec3 vViewPosition;

#ifdef USE_TRANSMISSION

varying vec3 vWorldPosition;

#endif

#include <common>
#include <batching_pars_vertex>
#include <uv_pars_vertex>
#include <displacementmap_pars_vertex>
#include <color_pars_vertex>
#include <fog_pars_vertex>
#include <normal_pars_vertex>
#include <morphtarget_pars_vertex>
#include <skinning_pars_vertex>
#include <shadowmap_pars_vertex>
#include <logdepthbuf_pars_vertex>
#include <clipping_planes_pars_vertex>

//varying vec2 vUv;

uniform float u_Progress;
uniform float u_Time;

uniform float u_key_1;
uniform float u_key_2;
uniform float u_key_3;
uniform float u_key_4;
uniform float u_key_5;
uniform float u_key_6;
uniform float u_key_7;
uniform float u_key_8;

uniform float u_mod_1_1;
uniform float u_mod_1_2;
uniform float u_mod_1_3;
uniform float u_mod_1_4;

uniform float u_mod_2_1;
uniform float u_mod_2_2;
uniform float u_mod_2_3;
uniform float u_mod_2_4;

uniform float u_mod_3_1;
uniform float u_mod_3_2;
uniform float u_mod_3_3;
uniform float u_mod_3_4;

uniform float u_mod_4_1;
uniform float u_mod_4_2;
uniform float u_mod_4_3;
uniform float u_mod_4_4;

uniform float u_mod_5_1;
uniform float u_mod_5_2;
uniform float u_mod_5_3;
uniform float u_mod_5_4;

uniform float u_mod_6_1;
uniform float u_mod_6_2;
uniform float u_mod_6_3;
uniform float u_mod_6_4;

uniform float u_mod_7_1;
uniform float u_mod_7_2;
uniform float u_mod_7_3;
uniform float u_mod_7_4;

uniform float u_mod_8_1;
uniform float u_mod_8_2;
uniform float u_mod_8_3;
uniform float u_mod_8_4;

uniform float u_mod_9_1;
uniform float u_mod_9_2;
uniform float u_mod_9_3;
uniform float u_mod_9_4;

vec3 twistX(vec3 position, float angleDegrees) {
    float distance = length(position.yz);

    float angleRadians = angleDegrees * (3.14159265 / 180.0);

    float twistAngle = position.x * angleRadians;

    float s = sin(twistAngle);
    float c = cos(twistAngle);

    mat2 rotation = mat2(c, -s, s, c);
    vec2 twistedYZ = rotation * position.yz;

    return vec3(position.x, twistedYZ.x, twistedYZ.y);
}

mat3 twistXMatrix(vec3 position, float angleDegrees) {
    float distance = length(position.yz);
    float angleRadians = angleDegrees * (3.14159265 / 180.0);
    float twistAngle = position.x * angleRadians;

    float s = sin(twistAngle);
    float c = cos(twistAngle);

    mat3 rotationMatrix = mat3(
    1, 0, 0,
    0, c, -s,
    0, s, c
    );

    return rotationMatrix;
}

vec3 twistY(vec3 position, float angleDegrees) {
    float distance = length(position.xz);

    float angleRadians = angleDegrees * (3.14159265 / 180.0);

    float twistAngle = position.y * angleRadians;

    float s = sin(twistAngle);
    float c = cos(twistAngle);

    mat2 rotation = mat2(c, -s, s, c);
    vec2 twistedXZ = rotation * position.xz;


    return vec3(twistedXZ.x, position.y, twistedXZ.y);
}

mat3 twistYMatrix(vec3 position, float angleDegrees) {
    float distance = length(position.xz);
    float angleRadians = angleDegrees * (3.14159265 / 180.0);
    float twistAngle = position.y * angleRadians;

    float s = sin(twistAngle);
    float c = cos(twistAngle);

    mat3 rotationMatrix = mat3(
    c, 0, s,
    0, 1, 0,
    -s, 0, c
    );

    return rotationMatrix;
}


vec3 twistZ(vec3 position, float angleDegrees) {
    float distance = length(position.xy);

    float angleRadians = angleDegrees * (3.14159265 / 180.0);

    float twistAngle = position.z * angleRadians;

    float s = sin(twistAngle);
    float c = cos(twistAngle);

    mat2 rotation = mat2(c, -s, s, c);
    vec2 twistedXY = rotation * position.xy;

    return vec3(twistedXY.x, twistedXY.y, position.z);
}

float remap(float value, float from1, float to1, float from2, float to2) {
    return from2 + (value - from1) * (to2 - from2) / (to1 - from1);
}

vec3 twist(in vec3 value /*, vec3 normal */) {

    float progress_animation = abs( sin( u_Time * 0.02 ) );
    //float progress_animation = 0.3;
    progress_animation = remap(progress_animation, 0.0, 1.0, 0.0, u_key_8);

    if (progress_animation < u_key_1) {
        float progress = remap(progress_animation, 0.0, u_key_1, 0.0, 1.0);

        value *= twistYMatrix(value, u_mod_1_1 + (u_mod_2_1 * progress));
        value *= twistXMatrix(value, u_mod_1_2 + (u_mod_2_2 * progress));
        value *= twistYMatrix(value, u_mod_1_3 + (u_mod_2_3 * progress));
        value *= twistYMatrix(value, u_mod_1_4 + (u_mod_2_4 * progress));
    } else if (progress_animation < u_key_2) {
        float progress = remap(progress_animation, u_key_1, u_key_2, 0.0, 1.0);
        float summ_1 = u_mod_1_1 + u_mod_2_1;
        float summ_2 = u_mod_1_2 + u_mod_2_2;
        float summ_3 = u_mod_1_3 + u_mod_2_3;
        float summ_4 = u_mod_1_4 + u_mod_2_4;

        value *= twistYMatrix(value, summ_1 + (u_mod_3_1 * progress));
        value *= twistXMatrix(value, summ_2 + (u_mod_3_2 * progress));
        value *= twistYMatrix(value, summ_3 + (u_mod_3_3 * progress));
        value *= twistYMatrix(value, summ_4 + (u_mod_3_4 * progress));
    } else if (progress_animation < u_key_3){
        float progress = remap(progress_animation, u_key_2, u_key_3, 0.0, 1.0);
        float summ_1 = u_mod_1_1 + u_mod_2_1 + u_mod_3_1;
        float summ_2 = u_mod_1_2 + u_mod_2_2 + u_mod_3_2;
        float summ_3 = u_mod_1_3 + u_mod_2_3 + u_mod_3_3;
        float summ_4 = u_mod_1_4 + u_mod_2_4 + u_mod_3_4;

        value *= twistYMatrix(value, summ_1 + (u_mod_4_1 * progress));
        value *= twistXMatrix(value, summ_2 + (u_mod_4_2 * progress));
        value *= twistYMatrix(value, summ_3 + (u_mod_4_3 * progress));
        value *= twistYMatrix(value, summ_4 + (u_mod_4_4 * progress));
    } else if (progress_animation < u_key_4) {
        float progress = remap(progress_animation, u_key_3, u_key_4, 0.0, 1.0);
        float summ_1 = u_mod_1_1 + u_mod_2_1 + u_mod_3_1 + u_mod_4_1;
        float summ_2 = u_mod_1_2 + u_mod_2_2 + u_mod_3_2 + u_mod_4_2;
        float summ_3 = u_mod_1_3 + u_mod_2_3 + u_mod_3_3 + u_mod_4_3;
        float summ_4 = u_mod_1_4 + u_mod_2_4 + u_mod_3_4 + u_mod_4_4;

        value *= twistYMatrix(value, summ_1 + (u_mod_5_1 * progress));
        value *= twistXMatrix(value, summ_2 + (u_mod_5_2 * progress));
        value *= twistYMatrix(value, summ_3 + (u_mod_5_3 * progress));
        value *= twistYMatrix(value, summ_4 + (u_mod_5_4 * progress));
    } else if (progress_animation < u_key_5) {
        float progress = remap(progress_animation, u_key_4, u_key_5, 0.0, 1.0);
        float summ_1 = u_mod_1_1 + u_mod_2_1 + u_mod_3_1 + u_mod_4_1 + u_mod_5_1;
        float summ_2 = u_mod_1_2 + u_mod_2_2 + u_mod_3_2 + u_mod_4_2 + u_mod_5_2;
        float summ_3 = u_mod_1_3 + u_mod_2_3 + u_mod_3_3 + u_mod_4_3 + u_mod_5_3;
        float summ_4 = u_mod_1_4 + u_mod_2_4 + u_mod_3_4 + u_mod_4_4 + u_mod_5_4;

        value *= twistYMatrix(value, summ_1 + (u_mod_6_1 * progress));
        value *= twistXMatrix(value, summ_2 + (u_mod_6_2 * progress));
        value *= twistYMatrix(value, summ_3 + (u_mod_6_3 * progress));
        value *= twistYMatrix(value, summ_4 + (u_mod_6_4 * progress));
    } else if (progress_animation < u_key_6) {
        float progress = remap(progress_animation, u_key_5, u_key_6, 0.0, 1.0);
        float summ_1 = u_mod_1_1 + u_mod_2_1 + u_mod_3_1 + u_mod_4_1 + u_mod_5_1 + u_mod_6_1;
        float summ_2 = u_mod_1_2 + u_mod_2_2 + u_mod_3_2 + u_mod_4_2 + u_mod_5_2 + u_mod_6_2;
        float summ_3 = u_mod_1_3 + u_mod_2_3 + u_mod_3_3 + u_mod_4_3 + u_mod_5_3 + u_mod_6_3;
        float summ_4 = u_mod_1_4 + u_mod_2_4 + u_mod_3_4 + u_mod_4_4 + u_mod_5_4 + u_mod_6_4;

        value *= twistYMatrix(value, summ_1 + (u_mod_7_1 * progress));
        value *= twistXMatrix(value, summ_2 + (u_mod_7_2 * progress));
        value *= twistYMatrix(value, summ_3 + (u_mod_7_3 * progress));
        value *= twistYMatrix(value, summ_4 + (u_mod_7_4 * progress));
    } else if (progress_animation < u_key_7) {
        float progress = remap(progress_animation, u_key_6, u_key_7, 0.0, 1.0);
        float summ_1 = u_mod_1_1 + u_mod_2_1 + u_mod_3_1 + u_mod_4_1 + u_mod_5_1 + u_mod_6_1 + u_mod_7_1;
        float summ_2 = u_mod_1_2 + u_mod_2_2 + u_mod_3_2 + u_mod_4_2 + u_mod_5_2 + u_mod_6_2 + u_mod_7_2;
        float summ_3 = u_mod_1_3 + u_mod_2_3 + u_mod_3_3 + u_mod_4_3 + u_mod_5_3 + u_mod_6_3 + u_mod_7_3;
        float summ_4 = u_mod_1_4 + u_mod_2_4 + u_mod_3_4 + u_mod_4_4 + u_mod_5_4 + u_mod_6_4 + u_mod_7_4;

        value *= twistYMatrix(value, summ_1 + (u_mod_8_1 * progress));
        value *= twistXMatrix(value, summ_2 + (u_mod_8_2 * progress));
        value *= twistYMatrix(value, summ_3 + (u_mod_8_3 * progress));
        value *= twistYMatrix(value, summ_4 + (u_mod_8_4 * progress));
    } else if (progress_animation <= u_key_8) {
        float progress = remap(progress_animation, u_key_7, u_key_8, 0.0, 1.0);
        float summ_1 = u_mod_1_1 + u_mod_2_1 + u_mod_3_1 + u_mod_4_1 + u_mod_5_1 + u_mod_6_1 + u_mod_7_1 + u_mod_8_1;
        float summ_2 = u_mod_1_2 + u_mod_2_2 + u_mod_3_2 + u_mod_4_2 + u_mod_5_2 + u_mod_6_2 + u_mod_7_2 + u_mod_8_2;
        float summ_3 = u_mod_1_3 + u_mod_2_3 + u_mod_3_3 + u_mod_4_3 + u_mod_5_3 + u_mod_6_3 + u_mod_7_3 + u_mod_8_3;
        float summ_4 = u_mod_1_4 + u_mod_2_4 + u_mod_3_4 + u_mod_4_4 + u_mod_5_4 + u_mod_6_4 + u_mod_7_4 + u_mod_8_4;

        value *= twistYMatrix(value, summ_1 + (u_mod_9_1 * progress));
        value *= twistXMatrix(value, summ_2 + (u_mod_9_2 * progress));
        value *= twistYMatrix(value, summ_3 + (u_mod_9_3 * progress));
        value *= twistYMatrix(value, summ_4 + (u_mod_9_4 * progress));
    }

    return value;
}


void main() {

    #include <uv_vertex>
    #include <color_vertex>
    #include <morphinstance_vertex>
    #include <morphcolor_vertex>
    #include <batching_vertex>

    #include <beginnormal_vertex>

    vec3 biTangent = cross(normal, tangent.xyz);
    float shift = 0.001;

    vec3 positionA = position + tangent.xyz * shift;
    vec3 positionB = position + biTangent * shift;

    positionA = twist(positionA);
    positionB = twist(positionB);
    vec3 pos = twist(position);

    // Compute normal
    vec3 toA = normalize(positionA - pos);
    vec3 toB = normalize(positionB - pos);
    objectNormal = cross(toA, toB);

    objectTangent = toA;

    #include <morphnormal_vertex>
    #include <skinbase_vertex>
    #include <skinnormal_vertex>
    #include <defaultnormal_vertex>
    #include <normal_vertex>
    #include <begin_vertex>

    transformed = pos;

    #include <morphtarget_vertex>
    #include <skinning_vertex>
    #include <displacementmap_vertex>
    #include <project_vertex>
    #include <logdepthbuf_vertex>
    #include <clipping_planes_vertex>


    vViewPosition = - mvPosition.xyz;

    #include <worldpos_vertex>
    #include <shadowmap_vertex>
    #include <fog_vertex>

    #ifdef USE_TRANSMISSION

    vWorldPosition = worldPosition.xyz;

    #endif

    //vUv = uv;
}
