<template>
	<AppModal
		v-bind="$attrs"
		class="video-modal video-modal--youtube"
		content-transition="vfm-slide-down"
		@opened="onOpened"
		@closed="onClosed"
		displayDirective="show"
	>
		<div class="modal__main">
			<div class="video-responsive">
				<div ref="modalVideoPlayer"></div>
			</div>
		</div>
	</AppModal>
</template>

<script>
import YouTubePlayer from 'youtube-player';
import AppModal from '@/components/AppModal.vue';

export default {
	name: 'YoutubeVideoModal',
	props: {
		videoId: {
			type: String,
			required: true,
		},
	},
	components: {
		AppModal,
	},
	data() {
		return {
			player: null,
			isPlayerReady: false,
		};
	},
	watch: {
		videoId(newId) {
			if (this.isPlayerReady && newId) {
				this.player.loadVideoById(newId);
			}
		},
	},
	methods: {
		onOpened() {
			if (!this.player) {
				this.player = YouTubePlayer(this.$refs.modalVideoPlayer);
				this.isPlayerReady = true;

				this.player.loadVideoById(this.videoId);
			} else if (this.videoId) {
				this.player.loadVideoById(this.videoId);
			}
			this.player.playVideo();
		},
		onClosed() {
			if (this.player) {
				this.player.stopVideo();
			}
			this.$emit('close');
		},
	},
};
</script>
