<template>
	<div :key="index" class="faq__item faq-item"  ref="collapse">
		<div class="faq-item__number">
			{{ index + 1 < 10 ? '(0' + (index + 1) + ')' : '(' + (index + 1) + ')' }}
		</div>

		<div class="faq-item__wrapper">
			<div class="faq-item__heading" ref="heading" @click="toggleCollapseActivity ">
				<span class="faq-item__question font-heading">
					{{ faqItemData.question }}
				</span>

				<span class="faq-item__icon"></span>
			</div>

			<div class="faq-item__answer" ref="answer">
				<p>
					{{ faqItemData.answer }}
				</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'FaqCollapse',
	props: {
		faqItemData: {
			type: Object,
			required: true
		},
		index: {
			type: Number,
			required: true
		}
	},
	mounted() {
		window.addEventListener('resize', this.setMaxHeight)
	},
	methods: {
		toggleCollapseActivity() {
			if (this.$refs.collapse.classList.contains('active')) {
				this.$refs.collapse.classList.remove('active');
			} else {
				this.setMaxHeight()
				this.$refs.collapse.classList.add('active');
			}
		},
		setMaxHeight() {
			let maxHeight = this.$refs.answer.scrollHeight + 'px';
			this.$refs.answer.style.setProperty('--max-height', maxHeight)
		}
	},
	unmounted() {
		window.removeEventListener('resize', this.setMaxHeight)
	}
};
</script>
