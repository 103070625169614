#include <packing>

varying vec2 vUv;
uniform sampler2D u_Texture;
uniform sampler2D u_TextTexture;
uniform sampler2D u_DepthTexture;
uniform sampler2D u_GridTexture;
uniform vec2 u_Resolution;
uniform vec2 u_TextTextureWH;
uniform vec2 u_GridTextureWH;
uniform float cameraNear;
uniform float cameraFar;
uniform float u_Time;
uniform float u_TextTextureProgress;
uniform float u_Scroll;
uniform float u_ScrollTranslate;
uniform vec2 u_RepeatMap;
uniform vec2 u_RepeatMapText;
uniform vec2 u_RepeatMapGrid;
uniform float u_DPR;
uniform float u_TransitionProgress;

float readDepth( sampler2D depthSampler, vec2 coord ) {
    float fragCoordZ = texture2D( depthSampler, coord ).x;
    float viewZ = perspectiveDepthToViewZ( fragCoordZ, cameraNear, cameraFar );
    return viewZToOrthographicDepth( viewZ, cameraNear, cameraFar );
}

void main() {

    vec4 color = vec4(1.0);

    float progressTexture = u_TextTextureProgress;

    vec2 screenUV = gl_FragCoord.xy / u_Resolution;

    vec2 backUv = vUv * u_RepeatMap;
    backUv *= 9.0;
    backUv += u_Time * 0.1;

    vec2 uvTextTexture = vUv * u_RepeatMapText;
    uvTextTexture.y += ((u_TextTextureWH.y - u_Resolution.y) / u_Resolution.y) * u_RepeatMapText.y;
    uvTextTexture.y -= (u_ScrollTranslate / u_Resolution.y) * u_RepeatMapText.y * u_DPR;


    if ( u_TransitionProgress > -0.2 ) {
        uvTextTexture.y -= 9999.0;
    }

    vec2 uvv = u_Resolution / u_GridTextureWH;

    vec2 gridUv = vUv;

    vec4 diffuse = texture2D( u_Texture, backUv ).rgba;
    vec4 diffuseText = texture2D( u_TextTexture, uvTextTexture ).rgba;
    vec4 diffuseGrid = texture2D( u_GridTexture, gridUv ).rgba;
    //float depth = readDepth( u_DepthTexture, screenUV );
    vec4 depth = texture2D( u_DepthTexture, vUv );

    // fix repeat artefact diffuseText
    if ( uvTextTexture.y > 1.0  || uvTextTexture.y < 0.0 ) {
        diffuseText = vec4(0.0);
    }



    // discard if circle in center

//    vec2 discardUv = screenUV;
//
//    // Normalized pixel coordinates (from -1 to 1)
//    discardUv *= 2.0;
//    discardUv -= 1.0;
//
//    // Fix resize window
//    discardUv.x *= u_Resolution.x / u_Resolution.y;
//
//    if ( length(discardUv) > 0.7) {
//        discard;
//    }

    // add static alpha channel
    diffuseText *= diffuseText.a;

    color.rgb = mix(diffuse.rgb, diffuseText.rgb, progressTexture);

    //diffuseGrid *= 2.0;

    vec4 greenTest = diffuseGrid;

    if( diffuseGrid.a <= 1.0 && diffuseGrid.r < diffuseGrid.g ) {
        //diffuseGrid.rgb = vec3(1.0 - diffuseGrid.a) * 0.012;

        vec3 grayGrid = vec3(1.0 - diffuseGrid.a) * 0.012;

        //diffuseGrid.rgb = vec3(1.0 - diffuseGrid.a) * 0.012;
        diffuseGrid.rgb = mix( grayGrid, diffuseGrid.rgb, diffuseGrid.a * 0.3 );

        if( depth.r != 0.0 ) {
            diffuseGrid.rgb *= 3.0;
        }
    } else {
        diffuseGrid.rgb = vec3(1.0 - diffuseGrid.a) * 0.012;
    }
//
//    diffuseGrid *= 2.0;
//    if( diffuseGrid.a < 1.0 ) {
//        diffuseGrid.rgb = vec3(.01);
//    }

    vec4 gridWithText = ( diffuseGrid * progressTexture ) + color;

//    gl_FragColor.rgb = 1.0 - vec3( depth );
    gl_FragColor.rgb = gridWithText.rgb * (4.0 - (progressTexture * 3.0));
    gl_FragColor.a = color.a;


    //gl_FragColor.rgb = vec3( depth );

    #include <tonemapping_fragment>
    #include <colorspace_fragment>
//    #include <fog_fragment>
//    #include <premultiplied_alpha_fragment>
//    #include <dithering_fragment>
}
