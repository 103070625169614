<template>
	<AppLayout>
		<FaqSection data-progress-step />
	</AppLayout>
</template>

<script>
import AppLayout from '@/components/AppLayout.vue';
import FaqSection from '@/components/FaqView/FaqSection.vue';

export default {
	name: 'FAQView',
	components: { AppLayout, FaqSection },
};
</script>
