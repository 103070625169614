<template>
	<svg
		width="153"
		height="46"
		viewBox="0 0 153 46"
		fill="none"
		preserveAspectRatio="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g filter="url(#filter0_b_180_1902)">
			<path
				d="M1 23C1 10.8497 10.8497 1 23 1H111C123.15 1 141 24 153 23C141 24 123.15 45 111 45H23C10.8497 45 1 35.1503 1 23Z"
				fill="#131313"
				fill-opacity="0.5"
			/>
			<path
				d="M143.339 27.2892 C146.867 25.1937 150.144 23.7454 153.021 23.5 V23L152.958 22.5017C150.114 22.7387 146.859 21.5528 143.33 19.5495C139.812 17.5525 136.102 14.7915 132.357 11.9754C132.181 11.8432 132.005 11.7108 131.829 11.5785C128.269 8.90039 124.686 6.20478 121.258 4.14932C117.671 1.99844 114.179 0.5 111 0.5H23C10.5736 0.5 0.5 10.5736 0.5 23C0.5 35.4264 10.5736 45.5 23 45.5H111C114.168 45.5 117.654 44.1359 121.244 42.1401C124.843 40.1395 128.611 37.4661 132.348 34.7811C132.6 34.6 132.852 34.4188 133.104 34.2378C136.595 31.7275 140.049 29.244 143.339 27.2892Z"
				stroke="currentColor"
			/>
		</g>
		<defs>
			<filter
				id="filter0_b_180_1902"
				x="-4"
				y="-4"
				width="157"
				height="54"
				filterUnits="userSpaceOnUse"
				color-interpolation-filters="sRGB"
			>
				<feFlood flood-opacity="0" result="BackgroundImageFix" />
				<feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
				<feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_180_1902" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_180_1902" result="shape" />
			</filter>
		</defs>
	</svg>
</template>
