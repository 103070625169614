<template>
	<svg
		width="173"
		height="46"
		viewBox="152.5 0.2 173 46"
		fill="none"
		preserveAspectRatio="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g filter="url(#filter0_b_180_19023)">
			<path
				d="M325 23C313 23 295.15 1 283 1H195C182.85 1 165 23 153 23C165 23 182.85 45 195 45H283C295.15 45 313 23 325 23Z"
				fill="#131313"
				fill-opacity="0.5"
			/>
			<path
				d="M148.339 22.5 C146.867 25.1937 150.144 23.7454 153.021 23.5C155.878 23.5064 159.141 24.8228 162.665 26.8699C166.171 28.9059 169.864 31.6213 173.592 34.3617L173.647 34.4028C177.383 37.1489 181.151 39.9165 184.749 41.9954C188.337 44.0688 191.826 45.5 195 45.5H283C286.174 45.5 289.663 44.0688 293.251 41.9954C296.849 39.9165 300.617 37.1489 304.353 34.4028L304.409 34.3614C308.136 31.6211 311.83 28.9059 315.335 26.8699C318.868 24.8178 322.137 23.5 325 23.5C331.132 21.1822 327.863 22.5 325 22.5C322.137 22.5 318.868 21.1822 315.335 19.1301C311.83 17.0941 308.136 14.3789 304.409 11.6386L304.353 11.5972C300.617 8.85114 296.849 6.08348 293.251 4.00457C289.663 1.93117 286.174 0.5 283 0.5H195C191.826 0.5 188.337 1.93117 184.749 4.00457C181.151 6.08348 177.383 8.85114 173.647 11.5972L173.592 11.6383C169.864 14.3787 166.171 17.0941 162.665 19.1301C159.132 21.1822 155.863 22.5 153 22.5V23L152.958 22.5017 Z"
				stroke="currentColor"
			/>
		</g>
		<defs>
			<filter
				id="filter0_b_180_19023"
				x="152"
				y="-4"
				width="174"
				height="54"
				filterUnits="userSpaceOnUse"
				color-interpolation-filters="sRGB"
			>
				<feFlood flood-opacity="0" result="BackgroundImageFix" />
				<feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
				<feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_180_1902" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_180_1902" result="shape" />
			</filter>
		</defs>
	</svg>
</template>
