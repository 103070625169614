import * as THREE from 'three'
import Experience from '../Experience.js'

import normalizeWheel from 'normalize-wheel'
import Sizes from "./Sizes.js";
import { MathUtils } from 'three';

export default class Input {
    static _instance = null

    static getInstance() {
        return Input._instance || new Input()
    }

    constructor() {
        if ( Input._instance ) {
            return Input._instance
        }
        Input._instance = this

        this.experience = Experience.getInstance()
        this.sizes = Sizes.getInstance()

        // Wait for resources
        this.experience.on( 'classesReady', () => {
            this.camera = this.experience.camera.instance
        } )

        this.cursor = { x: 0, y: 0, side: 'left'}
        this.cursor3D = new THREE.Vector3()
        this.cursor3D_Smooth = new THREE.Vector3()
        this.cursorDirection = new THREE.Vector3()
        this.clickPosition = new THREE.Vector2( 0, 0 );
        this.clientX = 0
        this.clientY = 0
        this.raycaster = new THREE.Raycaster()

        this.appScroll = {
            progressTarget: 0,
            progress: 0,
            translateY: 0,
            translateY_Target: 0,
            progressSteps: [0,0,0,0,0,0,0,0,0]
        }

        this.init()
        this._setCustomListeners()
    }

    init() {
        window.addEventListener( 'mousemove', this._onMouseMoved)
        window.addEventListener( 'touchstart', this._onTouchStart)
        window.addEventListener( 'touchmove', this._onTouchMoved)
        window.addEventListener('click', this._onClick)
    }

    postInit() {

    }

    projectNDCTo3D(x, y) {
        const vector = new THREE.Vector3(x, y, 0.5);
        vector.unproject(this.camera);

        const dir = vector.sub(this.camera.position).normalize(); // Direction from camera to point in NDC
        const cameraDirection = new THREE.Vector3();
        this.camera.getWorldDirection(cameraDirection); // Camera view direction

        // Distance to the plane perpendicular to the camera view direction
        const distance = - this.camera.position.dot(cameraDirection) / dir.dot(cameraDirection);

        // Point in 3D space
        return this.camera.position.clone().add(dir.multiplyScalar(distance));
    }

    getNDCFrom3d(x, y, z) {
        const vector = new THREE.Vector3( x, y, z );
        vector.project( this.camera );
        return vector;
    }


    _onMouseMoved = ( event ) =>{
        this.clientX = event.clientX
        this.clientY = event.clientY

        this.cursor.x = event.clientX / this.sizes.width * 2 - 1
        this.cursor.y = -( event.clientY / this.sizes.height ) * 2 + 1
        this.cursor.side = event.clientX > this.sizes.width / 2 ? 'right' : 'left'

        this.previosCursor3D = this.cursor3D.clone()
        this.cursor3D = this.projectNDCTo3D(this.cursor.x, this.cursor.y)
        this.cursorDirection = this.cursor3D.clone().sub(this.previosCursor3D).normalize()
    }

    _onTouchStart = ( event ) => {
        this._onTouchMoved( event )
    }

    _onTouchMoved = ( event ) => {
        this.cursor.x = event.touches[ 0 ].clientX / this.sizes.width * 2 - 1
        this.cursor.y = -( event.touches[ 0 ].clientY / this.sizes.height ) * 2 + 1
        this.cursor.side = event.touches[ 0 ].clientX > this.sizes.width / 2 ? 'right' : 'left'

        this.previosCursor3D = this.cursor3D.clone()
        this.cursor3D = this.projectNDCTo3D(this.cursor.x, this.cursor.y)
        this.cursorDirection = this.cursor3D.clone().sub(this.previosCursor3D).normalize()
    }

    _onClick = ( event ) => {
        // calculate tap position in normalized device coordinates (-1 to +1) for both components.
        this.clickPosition.x = ( event.clientX / window.innerWidth ) * 2 - 1;
        this.clickPosition.y = -( event.clientY / window.innerHeight ) * 2 + 1;

        this.shiftKey = event.shiftKey;
        this.ctrlKey = event.ctrlKey;

        this.experience.trigger('click', [event, this.clickPosition])
    }

    _setCustomListeners() {
        window.addEventListener('app:normalized-scroll-y', (event) => {
            this.appScroll.progressTarget = event.detail.progress ? event.detail.progress : 0;
            this.appScroll.translateY_Target = event.detail.translateY
            this.appScroll.progressSteps = event.detail.progressSteps
        })
    }

	update( deltaTime ) {
		this.cursor3D_Smooth.set(
			MathUtils.damp( this.cursor3D_Smooth.x, this.cursor3D.x, 5.1, deltaTime ),
			MathUtils.damp( this.cursor3D_Smooth.y, this.cursor3D.y, 5.1, deltaTime ),
			MathUtils.damp( this.cursor3D_Smooth.z, this.cursor3D.z, 5.1, deltaTime )
		)

		if ( this.experience.isMobile ) {
			this.appScroll.progress = MathUtils.damp( this.appScroll.progress, this.appScroll.progressTarget, 12.0, deltaTime )
			this.appScroll.translateY = MathUtils.damp( this.appScroll.translateY, this.appScroll.translateY_Target, 12.0, deltaTime )
		} else {
			this.appScroll.progress = this.appScroll.progressTarget
			this.appScroll.translateY = this.appScroll.translateY_Target
		}
	}
}
