uniform vec2 uResolution;
uniform float uSize;
uniform float uTime;
uniform float uSpeed;
uniform float uPerlinMultiplier;
uniform float uPerlinFrequency;
uniform float uTimeFrequency;
uniform float uSeed;
uniform float uProgress;

uniform vec3 uPointA;
uniform vec3 uPointB;
uniform vec3 uControlPoint1;
uniform vec3 uControlPoint2;

uniform sampler2D uParticlesTexture;


attribute float aSize;
attribute float aStartTime;
attribute vec3 aColor;
attribute vec2 aParticlesUv;

varying vec3 vColor;
varying float vStartTime;

vec3 bezierCurve(vec3 a, vec3 b, vec3 c, vec3 d, float t) {
    float invT = 1.0 - t;
    float invT2 = invT * invT;
    float invT3 = invT2 * invT;

    float t2 = t * t;
    float t3 = t2 * t;

    return a * invT3 + 3.0 * b * invT2 * t + 3.0 * c * invT * t2 + d * t3;
}

vec4 permute(vec4 x){return mod(((x*34.0)+1.0)*x, 289.0);}
vec4 taylorInvSqrt(vec4 r){return 1.79284291400159 - 0.85373472095314 * r;}
vec3 fade(vec3 t) {return t*t*t*(t*(t*6.0-15.0)+10.0);}

float perlin3d(vec3 P)
{
    vec3 Pi0 = floor(P); // Integer part for indexing
    vec3 Pi1 = Pi0 + vec3(1.0); // Integer part + 1
    Pi0 = mod(Pi0, 289.0);
    Pi1 = mod(Pi1, 289.0);
    vec3 Pf0 = fract(P); // Fractional part for interpolation
    vec3 Pf1 = Pf0 - vec3(1.0); // Fractional part - 1.0
    vec4 ix = vec4(Pi0.x, Pi1.x, Pi0.x, Pi1.x);
    vec4 iy = vec4(Pi0.yy, Pi1.yy);
    vec4 iz0 = Pi0.zzzz;
    vec4 iz1 = Pi1.zzzz;

    vec4 ixy = permute(permute(ix) + iy);
    vec4 ixy0 = permute(ixy + iz0);
    vec4 ixy1 = permute(ixy + iz1);

    vec4 gx0 = ixy0 / 7.0;
    vec4 gy0 = fract(floor(gx0) / 7.0) - 0.5;
    gx0 = fract(gx0);
    vec4 gz0 = vec4(0.5) - abs(gx0) - abs(gy0);
    vec4 sz0 = step(gz0, vec4(0.0));
    gx0 -= sz0 * (step(0.0, gx0) - 0.5);
    gy0 -= sz0 * (step(0.0, gy0) - 0.5);

    vec4 gx1 = ixy1 / 7.0;
    vec4 gy1 = fract(floor(gx1) / 7.0) - 0.5;
    gx1 = fract(gx1);
    vec4 gz1 = vec4(0.5) - abs(gx1) - abs(gy1);
    vec4 sz1 = step(gz1, vec4(0.0));
    gx1 -= sz1 * (step(0.0, gx1) - 0.5);
    gy1 -= sz1 * (step(0.0, gy1) - 0.5);

    vec3 g000 = vec3(gx0.x,gy0.x,gz0.x);
    vec3 g100 = vec3(gx0.y,gy0.y,gz0.y);
    vec3 g010 = vec3(gx0.z,gy0.z,gz0.z);
    vec3 g110 = vec3(gx0.w,gy0.w,gz0.w);
    vec3 g001 = vec3(gx1.x,gy1.x,gz1.x);
    vec3 g101 = vec3(gx1.y,gy1.y,gz1.y);
    vec3 g011 = vec3(gx1.z,gy1.z,gz1.z);
    vec3 g111 = vec3(gx1.w,gy1.w,gz1.w);

    vec4 norm0 = taylorInvSqrt(vec4(dot(g000, g000), dot(g010, g010), dot(g100, g100), dot(g110, g110)));
    g000 *= norm0.x;
    g010 *= norm0.y;
    g100 *= norm0.z;
    g110 *= norm0.w;
    vec4 norm1 = taylorInvSqrt(vec4(dot(g001, g001), dot(g011, g011), dot(g101, g101), dot(g111, g111)));
    g001 *= norm1.x;
    g011 *= norm1.y;
    g101 *= norm1.z;
    g111 *= norm1.w;

    float n000 = dot(g000, Pf0);
    float n100 = dot(g100, vec3(Pf1.x, Pf0.yz));
    float n010 = dot(g010, vec3(Pf0.x, Pf1.y, Pf0.z));
    float n110 = dot(g110, vec3(Pf1.xy, Pf0.z));
    float n001 = dot(g001, vec3(Pf0.xy, Pf1.z));
    float n101 = dot(g101, vec3(Pf1.x, Pf0.y, Pf1.z));
    float n011 = dot(g011, vec3(Pf0.x, Pf1.yz));
    float n111 = dot(g111, Pf1);

    vec3 fade_xyz = fade(Pf0);
    vec4 n_z = mix(vec4(n000, n100, n010, n110), vec4(n001, n101, n011, n111), fade_xyz.z);
    vec2 n_yz = mix(n_z.xy, n_z.zw, fade_xyz.y);
    float n_xyz = mix(n_yz.x, n_yz.y, fade_xyz.x);
    return 2.2 * n_xyz;
}

vec3 scale(vec3 v, float s) {
    return vec3(v.x * s, v.y * s, v.z * s);
}

void main()
{
//    float cycleDuration = 1.0;  // продолжительность одного цикла
//    float cyclicTime = mod((uTime * uSpeed - aStartTime), cycleDuration);
//    float progress = cyclicTime / cycleDuration;

    //float time = uProgress * 10.0;
    //float time = clamp(uTime * 0.7, 0.0, 10.0);
    float time = clamp(uProgress * 10.0, 0.0, 10.0);

    vec4 particleSim = texture(uParticlesTexture, aParticlesUv);


    float lifeTime = aStartTime;


    float progress = clamp((time - aStartTime * 4.0) * 0.2, 0.0, 1.0); // Деление на 10 определяет продолжительность анимации

    if (progress >= 1.0) {
        float cycleDuration = 1.0;  // продолжительность одного цикла
        float cyclicTime = mod((time * uSpeed - aStartTime), cycleDuration);
        //progress = cyclicTime / cycleDuration;
    }

    vec3 pointA = uPointA; // Пример начальной точки
    vec3 pointB = uPointB;  // Пример конечной точки
    vec3 controlPoint1 = uControlPoint1; // Пример первой контрольной точки
    vec3 controlPoint2 = uControlPoint2; // Пример второй контрольной точки

    //new THREE.Vector3(1.0, 0.0, 2.0),
    //controlPoint2.x += progress * 2.0;



    pointB.y -= time * 0.2;


    vec3 updatedPosition = bezierCurve(pointA, controlPoint1, controlPoint2, pointB, progress);

    //updatedPosition = mix(updatedPosition, scale(updatedPosition, 4.0), 0.3);

    float perlinMultiplier = 1.0 - uPerlinMultiplier * (1.6 - progress);

    perlinMultiplier = mix(perlinMultiplier, 2.0, time * 0.1);

    updatedPosition.x += perlin3d(uSeed + vec3(position.yz * uPerlinFrequency           , time * uTimeFrequency)) * perlinMultiplier;
    updatedPosition.y += perlin3d(uSeed + vec3(position.xz * uPerlinFrequency + 123.45  , time * uTimeFrequency)) * perlinMultiplier;
    updatedPosition.z += perlin3d(uSeed + vec3(position.xy * uPerlinFrequency + 12345.67, time * uTimeFrequency)) * perlinMultiplier;


    vec3 newPos = vec3(
        perlin3d(uSeed + vec3(position.yz * uPerlinFrequency, time * uTimeFrequency)) * perlinMultiplier * 3.0,
        perlin3d(uSeed + vec3(position.xz * uPerlinFrequency + 123.45  , time * uTimeFrequency)) * perlinMultiplier * 3.0,
        perlin3d(uSeed + vec3(position.xy * uPerlinFrequency + 12345.67, time * uTimeFrequency)) * perlinMultiplier * 3.0
    );

    updatedPosition = mix(updatedPosition.xyz, newPos, progress - 0.06);

    vec4 modelPosition = modelMatrix * vec4(updatedPosition, 1.0);

    vec4 viewPosition = viewMatrix * modelPosition;
    gl_Position = projectionMatrix * viewPosition;


    // Point size
    float sizeIn = smoothstep(0.0, 0.1, particleSim.a);
    float sizeOut = 1.0 - smoothstep(0.7, 1.0, particleSim.a);
    float size = min(sizeIn, sizeOut);

    gl_PointSize = size * uSize * aSize * uResolution.y;
    gl_PointSize *= (1.0 / - viewPosition.z);

    vColor = aColor;
    vStartTime = aStartTime;
}
