<template>
	<section :id="currentSection.id" class="content-section">
		<div class="container">
			<div class="content-section__wrapper">
				<div class="content-section__left">
					<AppPagination :current-section="currentSection" :sections="sections" />

					<div class="content-section__title font-heading">
						Industry proven experts
					</div>

					<div class="content-section__text-content">
						<p class="m-0">
							At SaGuNi-Tech, we have a team of experts with over 40 years of combined experience in technology and entrepreneurship. Our team includes software developers, 
						</p>

						<p class="m-0">
							designers, blockchain experts, and business strategists, all working together to create innovative solutions that push the boundaries of what's possible.
						</p>
					</div>
				</div>

				<div class="content-section__right">
					<img src="/images/about-us/content-section-image.jpg" alt="">
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import AppPagination from '@/components/AppPagination.vue';

export default {
	name: 'ContentSection',
	components: { AppPagination },
	props: {
		currentSection: {
			type: Object,
			required: true,
		},
		sections: {
			type: Array,
			required: true,
		},
	},
};
</script>
