import * as THREE from 'three';
import Experience from './Experience.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import gsap from 'gsap';

// import CameraControls from 'camera-controls';
// CameraControls.install( { THREE: THREE } );

export default class Camera {
	constructor() {
		this.experience = new Experience();
		this.sizes = this.experience.sizes;
		this.scene = this.experience.scene;
		this.time = this.experience.time;
		this.canvas = this.experience.canvas;
		this.timeline = this.experience.timeline;
		this.cursorEnabled = false;

		this.lerpVector = new THREE.Vector3();


		this.setInstance();
		this.setControls();

		this.resize();
	}

	setInstance() {
		this.instance = new THREE.PerspectiveCamera( 25, this.sizes.width / this.sizes.height, 0.1, 300 );
		this.defaultCameraPosition = new THREE.Vector3( 0, 0.0, 6 );

		this.instance.position.copy( this.defaultCameraPosition );
		this.instance.lookAt( new THREE.Vector3( 0, 0, 0 ) );

		this.lerpVector.copy( this.instance.position );

		this.scene.add( this.instance );
	}

	setControls() {
		this.controls = new OrbitControls( this.instance, this.canvas );
		this.controls.enableDamping = true;
		this.controls.minDistance = 0;
		this.controls.maxDistance = 500;
		this.controls.enabled = true;
		this.controls.target = new THREE.Vector3( 0, 0, 0 );


		// this.controls.mouseButtons = {
		//     LEFT: THREE.MOUSE.ROTATE,
		//     MIDDLE: null,
		//     RIGHT: null,  // Отключает действие для правой кнопки мыши
		// };
		//
		// this.controls.enableZoom = false;

		// this.experience.on('classesReady', () => {
		// 	this.renderer = this.experience.renderer.instance;
		// 	this.cameraControls = new CameraControls( this.instance, this.renderer.domElement );
		//
		// 	this.experience.world.on('ready', () => {
		// 		this.cameraControls.fitToBox( this.experience.world.sphereGlass.container, false, {
		// 			paddingTop: 2,
		// 			paddingRight: 2,
		// 			paddingBottom: 2,
		// 			paddingLeft: 2
		// 		} );
		// 	})
		//
		// });
	}

	setFOV() {
		const distance = this.instance.position.z;
		const focusObjectSize = 4;
		const aspect = window.innerWidth / window.innerHeight;

		// Увеличить FOV если мы уменьшаем экран
		//this.instance.fov = 2 * Math.atan( focusObjectSize / ( 2 * dist ) ) * ( 180 / Math.PI );
		//this.instance.fov = 2 * Math.atan( focusObjectSize / ( 2 * dist ) ) * ( 180 / Math.PI );

		// const objectWidth = 3;
		// const objectHeight = 3;
		//
		// const vFOV = 2 * Math.atan(objectHeight / (2 * distance)) * (180 / Math.PI);
		// const hFOV = 2 * Math.atan(objectWidth / (2 * distance)) * (180 / Math.PI);
		//
		// if (aspect > 1) {
		// 	this.instance.fov = Math.max(vFOV, hFOV / aspect);
		// } else {
		// 	this.instance.fov = Math.max(vFOV * aspect, hFOV);
		// }
		//
		// console.log(this.instance.fov)


		let aspectRatio = window.innerHeight / window.innerWidth;
		if ( aspectRatio < 1 ) {
			aspectRatio = 1;
		}

		this.instance.fov = 30 * aspectRatio;
	}

	resize() {
		this.instance.aspect = this.sizes.width / this.sizes.height;
		this.setFOV();
		this.instance.updateProjectionMatrix();
	}

	update( deltaTime ) {
		this.controls.update();

		//const hasControlsUpdated = this.cameraControls?.update( deltaTime );

		//this.instance.updateMatrixWorld() // To be used in projection
	}

	animateCameraPosition() {

	}
}
