<template>
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_202_196)">
			<path
				d="M14.2856 10.1635L23.2216 0H21.1048L13.3424 8.82302L7.1472 0H0L9.3704 13.3432L0 23.9999H2.1168L10.3088 14.6805L16.8528 23.9999H24M2.8808 1.56189H6.1328L21.1032 22.5148H17.8504"
				fill="currentColor"
			/>
		</g>
		<defs>
			<clipPath id="clip0_202_196">
				<rect width="24" height="24" fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
