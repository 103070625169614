varying vec3 vColor;
varying float vStartTime;

uniform float uTime;

void main() {

    //    float distanceToCenter = length(gl_PointCoord - 0.5);
    //    if(distanceToCenter > 0.5)
    //        discard;

    vec2 uv = gl_PointCoord;
    float distanceToCenter = length(uv - 0.5);
    float alpha = 0.05 / distanceToCenter - 1.95;

    gl_FragColor = vec4(vColor, alpha);

//    if(uTime * 0.6 - vStartTime > 3.0) {
//        discard;
//    }

    #include <tonemapping_fragment>
    #include <colorspace_fragment>
}
