<template>
	<svg width="70" height="72" viewBox="0 0 70 72" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect x="57.2754" width="16.9468" height="80.9985" transform="rotate(45 57.2754 0)" fill="currentColor" />
		<rect
			x="57.3691"
			y="0.0253906"
			width="16.9469"
			height="57.2804"
			transform="rotate(90 57.3691 0.0253906)"
			fill="currentColor"
		/>
		<rect
			x="69.2324"
			y="71.541"
			width="19.3194"
			height="17.6247"
			transform="rotate(-180 69.2324 71.541)"
			fill="currentColor"
		/>
		<path d="M49.9141 34.5969L69.2335 53.9163H49.9141V34.5969Z" fill="currentColor" />
	</svg>
</template>
