<template>
	<canvas id="screenshot-canvas"></canvas>
</template>

<script>
export default {
	name: 'ScreenshotCanvas',
	mounted() {
		this.$el.width = document.documentElement.clientWidth;
		this.$el.height = document.documentElement.clientHeight;
	},
};
</script>
