<template>
	<div class="app-pagination">
		<ul class="app-pagination__list list-unstyled">
			<a
				v-for="(item, index) in sections"
				:key="index"
				:href="`#${item.id}`"
				:class="{ 'app-pagination__item--active': item === currentSection }"
				class="app-pagination__item"
			>
				<span class="visually-hidden">{{ item.title }}</span>
			</a>
		</ul>

		<p v-if="currentSection.title" class="app-pagination__title m-0">{{ currentSection.title }}</p>
	</div>
</template>

<script>
export default {
	name: 'AppPagination',
	props: {
		currentSection: {
			type: Object,
			required: true,
		},
		sections: {
			type: Array,
			required: true,
		},
	},
};
</script>
