<template>
	<svg xmlns="http://www.w3.org/2000/svg" width="30" height="21" viewBox="0 0 30 21" fill="none">
		<g clip-path="url(#clip0_676_141)">
			<path d="M29.3374 3.27542C29.1654 2.64233 28.8297 2.06517 28.3639 1.6014C27.8981 1.13763 27.3183 0.803438 26.6824 0.6321C24.3544 0 14.9848 0 14.9848 0C14.9848 0 5.61471 0.0191333 3.28666 0.651233C2.65075 0.822582 2.07101 1.15679 1.60519 1.62058C1.13937 2.08437 0.80372 2.66156 0.631663 3.29467C-0.0725165 7.41277 -0.345681 13.6878 0.650999 17.6412C0.823074 18.2743 1.15873 18.8514 1.62455 19.3152C2.09037 19.779 2.6701 20.1131 3.306 20.2845C5.63405 20.9166 15.0039 20.9166 15.0039 20.9166C15.0039 20.9166 24.3736 20.9166 26.7015 20.2845C27.3375 20.1132 27.9172 19.779 28.3831 19.3152C28.8489 18.8514 29.1846 18.2743 29.3567 17.6412C30.0994 13.5172 30.3283 7.24605 29.3374 3.27542Z" fill="#FF0000"/>
			<path d="M12.002 14.9403L19.7748 10.4582L12.002 5.97607V14.9403Z" fill="white"/>
		</g>
		<defs>
			<clipPath id="clip0_676_141">
			<rect width="30" height="21" fill="white"/>
			</clipPath>
		</defs>
	</svg>
</template>
