const app = document.getElementById('app');

function disableScroll() {
	document.documentElement.style.overflow = 'hidden';
	app.style.overflow = 'hidden';
}

function enableScroll() {
	document.documentElement.style.overflow = '';
	app.style.overflow = '';
}

export { disableScroll, enableScroll };
