<template>
	<VeeForm class="form" :class="[formClass, {'message-visible': messageVisibility}]" @submit="onSubmit" ref="form">
		<div class="form__row">
			<div class="form__field">
				<label :for="`${name}-${$.uid}[name]`" class="form__label visually-hidden">Name</label>
				<VeeField
					:id="`${name}-${$.uid}[name]`"
					name="name"
					placeholder="NAME"
					rules="required"
					label="NAME"
					class="form__input"
				/>
				<VeeErrorMessage name="name" class="form__error" />
			</div>

			<div class="form__field">
				<label :for="`${name}-${$.uid}[cv]`" class="form__label visually-hidden">CV Link</label>
				<VeeField
					:id="`${name}-${$.uid}[cv]`"
					name="cv"
					placeholder="CV LINK"
					rules="required|url"
					label="CV LINK"
					class="form__input"
				/>
				<VeeErrorMessage name="cv" class="form__error" />
			</div>
		</div>

		<div class="form__row">
			<div class="form__field">
				<label :for="`${name}-${$.uid}[role]`" class="form__label visually-hidden">Job Role</label>
				<VeeField
					:id="`${name}-${$.uid}[role]`"
					name="role"
					rules="required"
					label="JOB ROLE"
					as="select"
					class="form__select"
				>				
					<option value="" disabled selected>JOB ROLE</option>
					<optgroup label="Game Development Roles">
						<option value="game-designer">Game Designer/Developer</option>
						<option value="artist">Artist/Animator</option>
						<option value="ui-ux-designer">UI/UX Designer</option>
						<option value="sound-designer">Sound Designer/Audio Engineer</option>
						<option value="qa-specialist">Quality Assurance Specialist</option>
						<option value="3d-designer">3D designer</option>
						<option value="vfx-artist">VFX ARTIST</option>
					</optgroup>

					<optgroup label="Web Development Roles">
						<option value="full-stack-developer">Full Stack Developer</option>
						<option value="web-ui-designer">Web/UI Designer</option>
						<option value="seo-manager">Web Content/SEO Manager</option>
						<option value="devops-engineer">DevOps Engineer</option>
						<option value="db-admin">Database Administrator</option>
						<option value="tech-support-specialist">Technical Support Specialist</option>
						<option value="web-project-manager">Web Project Manager</option>
					</optgroup>

					<optgroup label="Cross-functional Roles">
						<option value="technical-artist">Technical Artist</option>
						<option value="mobile-app-developer">Mobile App Developer</option>
						<option value="community-manager">Community Manager</option>
						<option value="data-analyst">Data Analyst</option>
						<option value="it-support-specialist">IT Support Specialist</option>
						<option value="system-admin">System Administrator</option>
						<option value="business-dev-manager">Business Development Manager</option>
						<option value="legal-counsel">Legal Counsel</option>
					</optgroup>

					<optgroup label="Support Roles">
						<option value="customer-support-rep">Customer Support Representative</option>
						<option value="tech-support-engineer">Technical Support Engineer</option>
					</optgroup>

					<optgroup label="OTHERS">
						<option value="other">Other</option>
					</optgroup>
				</VeeField>
				<VeeErrorMessage name="role" class="form__error" />
			</div>

			<div class="form__field">
				<label :for="`${name}-${$.uid}[email]`" class="form__label visually-hidden">Email</label>
				<VeeField
					:id="`${name}-${$.uid}[email]`"
					name="email"
					placeholder="EMAIL"
					rules="required|email"
					label="EMAIL"
					class="form__input"
				/>
				<VeeErrorMessage name="email" class="form__error" />
			</div>
		</div>

		<BaseCircleButton class="form__submit" type="submit">Submit</BaseCircleButton>
	</VeeForm>

	<div v-if="messageVisibility" class="form-message" :class="isErrorMessage ? 'error' : ''">
		{{ userMessage }}
	</div>
</template>

<script>
import { Form as VeeForm, Field as VeeField, ErrorMessage as VeeErrorMessage } from 'vee-validate';
import BaseCircleButton from '@/components/BaseCircleButton.vue';

export default {
	name: 'JobApplicationForm',
	components: { BaseCircleButton, VeeForm, VeeField, VeeErrorMessage },
	data: () => ({
		name: 'job-application-form',
		userMessage: '',
		messageVisibility: false,
		isErrorMessage: false,
	}),
	props: {
		formClass: {
			type: String,
			default: '',
		},
	},
	methods: {
		async onSubmit(values) {
			let data = {
				subject: 1,
				...values
			}
			try {
				const response = await fetch('/mail.php', {
					method: "POST",
					body: JSON.stringify(data),
					headers: {
						"Content-Type": "application/json",
					},
				});
				const json = await response.json();
				if (json.status === 'error') {
					let errorMessage;
					if (Array.isArray(json.errors)) {
						errorMessage = json.errors.join(', ');
					} else {
						errorMessage = json.errors;
					}
					this.onError()
					throw new Error(`Something went wrong: ${errorMessage}`);
				} else {
					this.onSuccess()
				}
			} catch (error) {
				this.onError()
				console.error("Ошибка:", error);
			}
		},
		onError() {
			this.userMessage = 'Something went wrong. Try again later';
			this.isErrorMessage = true
			this.setMessageVisibilty(true)
		},
		onSuccess() {
			this.userMessage = 'Thank you for contacting us!'
			this.isErrorMessage = false
			this.setMessageVisibilty(true)
			this.$refs.form.resetForm()
		},
		setMessageVisibilty(value) {
			this.messageVisibility = value
		}
	},
};
</script>
