import * as THREE from 'three';
import Model from './Abstracts/Model.js';
import Experience from '../Experience.js';
import Debug from '../Utils/Debug.js';
import State from '../State.js';
import Materials from '../Materials/Materials.js';
import FBO from '@experience/Utils/FBO.js';

import physicalMaterialVertex from '@experience/Shaders/PhysicalMaterial/vertex.glsl';
import physicalMaterialFragment from '@experience/Shaders/PhysicalMaterial/fragment.glsl';

import backgroundMaterialVertex from '@experience/Shaders/BackgroundMaterial/vertex.glsl';
import backgroundMaterialFragment from '@experience/Shaders/BackgroundMaterial/fragment.glsl';

import torusMaterialVertex from '@experience/Shaders/Torus/vertex.glsl';
import torusMaterialFragment from '@experience/Shaders/Torus/fragment.glsl';
import gsap from 'gsap';
import { MathUtils } from 'three';

export default class SphereGlass extends Model {
	experience = Experience.getInstance();
	debug = Debug.getInstance();
	state = State.getInstance();
	materials = Materials.getInstance();
	fbo = FBO.getInstance();
	input = this.experience.input;
	scroll = this.input.appScroll
	sizes = experience.sizes;
	scene = experience.scene;
	time = experience.time;
	timeline = experience.timeline;
	camera = experience.camera.instance;
	renderer = experience.renderer.instance;
	resources = experience.resources;
	container = new THREE.Group();

	positionScale = 30.0;

	_progress = 0;

	_sphereAnimationStartProgress = 0

	_keyframes = {
		key_1: 0.125,
		key_2: 0.20,
		key_3: 0.3,
		key_4: 0.4,
		key_5: 0.5,
		key_6: 0.6,
		key_7: 0.7,
		key_8: 0.8,
	};

	_mods = {
		mod_1_1: 180.0,
		mod_1_2: -33.0,
		mod_1_3: -33.0,
		mod_1_4: 297.0,

		mod_2_1: 84.0,
		mod_2_2: 477.0,
		mod_2_3: -162.0,
		mod_2_4: -15.0,

		mod_3_1: -464.0,
		mod_3_2: -794.0,
		mod_3_3: -33.0,
		mod_3_4: -8.0,

		mod_4_1: -236.0,
		mod_4_2: 626.0,
		mod_4_3: -58.0,
		mod_4_4: 94.0,

		mod_5_1: 930.0,
		mod_5_2: -439.0,
		mod_5_3: 195.0,
		mod_5_4: 43.0,

		mod_6_1: -1000.0,
		mod_6_2: 347.0,
		mod_6_3: -211.0,
		mod_6_4: -287.0,

		mod_7_1: 322.0,
		mod_7_2: -895.0,
		mod_7_3: 373.0,
		mod_7_4: -160.0,

		mod_8_1: 474.0,
		mod_8_2: 550.0,
		mod_8_3: -185.0,
		mod_8_4: -287.0,

		mod_9_1: 94.0,
		mod_9_2: 499.0,
		mod_9_3: 499.0,
		mod_9_4: 119.0,
	}

	_uvNormalScale = new THREE.Vector2(12.0, 12.0)

	constructor() {
		super();

		// for ( let i = 1; i < 9; i++ ) {
		// 	this._keyframes[ `key_${ i }` ] = 0.125 * i;
		// }

		// for ( let i = 1; i < 10; i++ ) {
		// 	for ( let j = 1; j < 5; j++ ) {
		// 		this._mods[ `mod_${ i }_${ j }` ] = 0.0;
		// 	}
		// }

		this.experience.world.on('ready', () => {
			this.sceneDepth = this.experience.world.sceneDepth;
			this.torusDepth = this.torus.clone()
			this.sceneDepth.container.add( this.torusDepth );
		})

		this.setSphere();
		this.setDebug();
	}

	setSphere() {

		this.resources.items.hdrTexture.colorSpace = THREE.SRGBColorSpace
		this.resources.items.normalTexture.colorSpace = THREE.SRGBColorSpace
		this.resources.items.normalTexture.wrapS = this.resources.items.normalTexture.wrapT = THREE.RepeatWrapping;

		// add plane
		// const planeGeometry = new THREE.PlaneGeometry( 5, 3 );
		// const planeMaterial = new THREE.MeshStandardMaterial( {
		// 	transparent: false
		// } );
		// planeMaterial.map = this.resources.items.testScreenTexture
		// planeMaterial.needsUpdate = true
		//
		// const plane = new THREE.Mesh( planeGeometry, planeMaterial );
		// plane.position.z = - 1;
		//
		// this.container.add( plane );


		// add torus geometry
		const geometry = new THREE.TorusGeometry( 0.55, 0.15, 32, 500 );
		geometry.computeTangents()
		const material = this.material = new THREE.MeshPhysicalMaterial(
			{
				color: 0xffffff,
				transmission: 1,
				opacity: 0.0,
				metalness: 0,
				roughness: 0.17,
				ior: 1.5,
				thickness: 0.087,
				clearcoat: 0.73,
				reflectivity: 0.5,
				dispersion: 2.16,
				attenuationColor: 0xffffff,
				attenuationDistance: 1,
				specularIntensity: 0,
				specularColor: 0xffffff,
				envMapIntensity: 1,
				transparent: true,
				normalMap: this.resources.items.normalTexture,
			}
		);

		material.onBeforeCompile = ( shader ) => {
			shader.vertexShader = physicalMaterialVertex;
			shader.fragmentShader = physicalMaterialFragment;

			material.uniforms = shader.uniforms;
			material.uniforms.u_Time = new THREE.Uniform( 0 );
			material.uniforms.u_Progress = new THREE.Uniform( 0 );
			material.uniforms.u_Resolution = new THREE.Uniform( new THREE.Vector2( this.sizes.width, this.sizes.height ) );
			material.uniforms.u_UVNormalScale = new THREE.Uniform( this._uvNormalScale );

			// keyframes
			for ( const [ key, value ] of Object.entries( this._keyframes ) ) {
				material.uniforms[ `u_${ key }` ] = new THREE.Uniform( value );
			}

			// mods
			for ( const [ key, value ] of Object.entries( this._mods ) ) {
				material.uniforms[ `u_${ key }` ] = new THREE.Uniform( value );
			}

			//shader.defines.USE_UV = true;
			//shader.defines.USE_TANGENT = true;
		};

		material.needsUpdate = true;

		const torus = this.torus = new THREE.Mesh( geometry, material);
		torus.scale.set(0.1, 0.1, 0.1)

		torus.rotation.x = Math.PI / 2;

		this.container.add( torus );

		this.scene.add( this.container );
	}

	setAnimation() {
		this.experience.on('cube:start-animation-out', () => {
			gsap.to( this, {
				duration: 3,
				_sphereAnimationStartProgress: 1.0,
				ease: "power2.out",
				onUpdate: () => {
					const progress = this._sphereAnimationStartProgress;
					this.torus.scale.setScalar( progress + 0.4 );
					this.torus.material.opacity = progress;

					this.torusDepth.scale.setScalar( progress + 0.4 );
					this.torusDepth.material.opacity = progress;
				},
			} )
		})
	}


	resize() {
		// this.planeMaterial.uniforms.u_Resolution.value.set( this.sizes.width * this.sizes.pixelRatio, this.sizes.height * this.sizes.pixelRatio )
		// this.depthRenderTarget.setSize( this.sizes.width * this.sizes.pixelRatio, this.sizes.height * this.sizes.pixelRatio )
		// this.depthRenderTarget.depthTexture.image.width = this.sizes.width * this.sizes.pixelRatio
		// this.depthRenderTarget.depthTexture.image.height = this.sizes.height * this.sizes.pixelRatio
		//
		// this.postUpdate( this.time.delta ) // fix flickering background objects
	}

	setDebug() {
		if ( !this.debug.active ) return;

		//this.debug.createDebugTexture( this.displacementTexture )

		if ( this.debug.panel ) {
			this.debugFolder = this.debug.panel.addFolder( 'Torus' );
			this.debugFolder.close()


			this.debugFolder.add( this, '_progress' )
				.onChange( () => {
					this.material.uniforms.u_Progress.value = this._progress;
				} )
				.min( 0 ).max( 1 ).step( 0.01 ).name( 'u_Progress' );

			// Object.keys( this._keyframes ).forEach( ( key ) => {
			// 	//onsole.log (key, this._keyframes[key])
			// 	this.debugFolder.add( this._keyframes, key )
			// 		.name( key )
			// 		.min( 0 ).max( 1 ).step( 0.01 )
			// 		.onChange( () => {
			// 			this.material.uniforms[ `u_${ key }` ].value = this._keyframes[ key ];
			// 		})
			// } );

			// Object.keys( this._mods ).forEach( ( key ) => {
			// 	//onsole.log (key, this._keyframes[key])
			// 	this.debugFolder.add( this._mods, key )
			// 		.name( key )
			// 		.min( -1000 ).max( 1000 ).step( 1 )
			// 		.onChange( () => {
			// 			this.material.uniforms[ `u_${ key }` ].value = this._mods[ key ];
			// 		})
			// })

			this.debugFolder = this.debug.panel.addFolder("First Material");
			this.debugFolder.close()

			this.debugFolder.add ( this._uvNormalScale, 'x' )
				.name( "u_UVNormalScale X" )
				.min( 0 ).max( 50 ).step( 0.01 )
				.onChange( () => {
					this.material.uniforms.u_UVNormalScale.value.x = this._uvNormalScale.x
				})
			this.debugFolder.add ( this._uvNormalScale, 'y' )
				.name( "u_UVNormalScale Y" )
				.min( 0 ).max( 50 ).step( 0.01 )
				.onChange( () => {
					this.material.uniforms.u_UVNormalScale.value.y = this._uvNormalScale.y
				})
			this.debugFolder.addColor( this.material, 'color' )
			this.debugFolder.add( this.material, 'transparent' )
			this.debugFolder.add( this.material, 'roughness', 0, 1, 0.01 )
			this.debugFolder.add( this.material, 'metalness', 0, 1, 0.01 )
			this.debugFolder.add( this.material, 'thickness', 0, 2, 0.001 )
			this.debugFolder.add( this.material, 'transmission', 0, 1, 0.01 )
			this.debugFolder.add( this.material, 'opacity', 0, 1, 0.01 )
			    .onChange( () => {
			        this.material.needsUpdate = true;
			    } );
			//this.debugFolder.add( this.material, 'anisotropy', 0, 1, 0.0001 )
			this.debugFolder.addColor( this.material, 'attenuationColor' )
			this.debugFolder.add( this.material, 'attenuationDistance', 0, 1, 0.01 )
			this.debugFolder.add( this.material, 'clearcoat', 0, 1, 0.01 )
			this.debugFolder.add( this.material.clearcoatNormalScale, 'x', 0, 1, 0.01 ).name( "clearcoatNormalScale x" )
			this.debugFolder.add( this.material.clearcoatNormalScale, 'y', 0, 1, 0.01 ).name( "clearcoatNormalScale y" )
			this.debugFolder.add( this.material, 'clearcoatRoughness', 0, 1, 0.01 )
			this.debugFolder.add( this.material, 'dispersion', 0, 10, 0.01 )
			this.debugFolder.add( this.material, 'ior', 1.0, 3, 0.01 )
			this.debugFolder.add( this.material, 'reflectivity', 0, 1, 0.01 )
			this.debugFolder.add( this.material, 'iridescence', 0, 1, 0.01 )
			this.debugFolder.add( this.material, 'iridescenceIOR', 1.0, 3, 0.01 )

			Object.keys(this.material.iridescenceThicknessRange).forEach((key) => {
			    this.debugFolder.add( this.material.iridescenceThicknessRange, key );
			})

			this.debugFolder.add( this.material, 'sheen', 0, 1, 0.01 )
			this.debugFolder.add( this.material, 'sheenRoughness', 0, 1, 0.01 )
			this.debugFolder.addColor( this.material, 'sheenColor' )
			this.debugFolder.add( this.material, 'specularIntensity', 0, 1, 0.01 )
			this.debugFolder.addColor( this.material, 'specularColor' )

		}
	}

	update( deltaTime ) {

		if ( this.material.uniforms ){
			this.material.uniforms.u_Time.value = this.time.elapsed;
		}

		if ( this.scroll.progress > this.scroll.progressSteps[ 0 ] ) {
			this.container.position.y = ( -this.scroll.progressSteps[ 0 ] * this.positionScale ) + this.scroll.progress * this.positionScale
			this.torusDepth.position.y = this.container.position.y
		} else {
			this.container.position.y = 0
			this.torusDepth.position.y = 0
		}

		//this.renderer.render( this.scene, this.camera );
		//this.renderer.render( this.postScene, this.postCamera );
	}

	postUpdate( deltaTime ) {

	}

}
