export default [
	// {
	//     name: 'backgroundSound',
	//     type: 'audio',
	//     path: '/sounds/background.mp3'
	// },
	// {
	//     name: 'predatorModel',
	//     type: 'gltfModel',
	//     path: '/models/predator.glb'
	// },
	{
		name: 'cubeModel',
		type: 'gltfModel',
		path: '/models/cube_v3.glb',
	},
	{
		name: 'dispersionModel',
		type: 'gltfModel',
		path: '/models/DispersionTest.glb',
	},
	{
		name: 'cardsModel',
		type: 'gltfModel',
		path: '/models/cards_v4.glb',
	},

	// Textures
	{
		name: 'hdrTexture',
		type: 'texture',
		path: '/textures/hdr_1.jpg',
	},
	{
		name: 'normalTexture',
		type: 'texture',
		path: '/textures/NormalMap_v4.png',
	},
	{
		name: 'transitionTexture',
		type: 'texture',
		path: '/textures/transition.png',
	},

	// Video Textures
	{
		name: 'callistoTexture',
		type: 'videoTexture',
		path: '/video/Callisto-Trailer.mp4',
	},
	{
		name: 'driveSafeTexture',
		type: 'videoTexture',
		path: '/video/DriveSafe.mp4',
	},
	{
		name: 'mangaTexture',
		type: 'videoTexture',
		path: '/video/Webtoon.mp4',
	},
	{
		name: 'evoVerseTexture',
		type: 'videoTexture',
		path: '/video/EvoVerse.mp4',
	},
	{
		name: 'cardGamesTexture',
		type: 'videoTexture',
		path: '/video/Elementel_Duels.mp4',
	},
	{
		name: 'tabmasterTexture',
		type: 'videoTexture',
		path: '/video/tabmaster.mp4',
	},
	{
		name: 'projectXTexture',
		type: 'videoTexture',
		path: '/video/project-x.mp4',
	},

	// cards textures
	{
		name: 'callistoImageTexture',
		type: 'texture',
		path: '/textures/cards/Callisto.png',
	},
	{
		name: 'driveSafeImageTexture',
		type: 'texture',
		path: '/textures/cards/DriveSafe.png',
	},
	{
		name: 'elementalDuelsImageTexture',
		type: 'texture',
		path: '/textures/cards/ElementalDuels.png',
	},
	{
		name: 'evoVerseImageTexture',
		type: 'texture',
		path: '/textures/cards/EvoVerse.png',
	},
	{
		name: 'webtoonImageTexture',
		type: 'texture',
		path: '/textures/cards/Webtoon.png',
	},
	{
		name: 'tabmasterImageTexture',
		type: 'texture',
		path: '/textures/cards/Tabmaster.png',
	},
	{
		name: 'projectXImageTexture',
		type: 'texture',
		path: '/textures/cards/ProjectX.png',
	},


	// {
	//     name: 'testScreenTexture',
	//     type: 'texture',
	//     path: '/textures/test_screen.png'
	// }
];
